.content.house-unit {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    background-color: #fff;

    max-width: 1400px;
    margin: auto;
}

.content.house-unit .header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-top: 60px;
}
.content.house-unit .header > div{
    display: flex;
    align-items: center;
}

.content.house-unit .header h2 {
    font-family: 'Lato', sans-serif;
    font-size: 40px;
    font-weight: 600;

    color: #379144;
}

.content.house-unit .header p {
    width: 100%;
    max-width: 720px;

    text-align: right;
}

.content.house-unit .header > div .bar {
    width: 100%;
    max-width: 0;
    height: 8px;

    margin-left: 0;

    animation: growBar2 1500ms ease-in-out forwards 500ms;
}

@keyframes growBar2 {
    0% {
        max-width: 0;
    }
    100% {
        max-width: 160px;
        margin-left: 16px;
    }
}

.content.house-unit .units {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 48px;
}

.content.house-unit .units .button-control {
    position: absolute;
    top: -72px;

    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 140px;
}
.content.house-unit .units .button-control button {
    height: 48px;
    width: 60px;
    font-size: 24px;

    border: 2px solid #10B981;
    background-color: #10B981;
    color: #fff;
    border-radius: 500px;

    cursor: pointer;
}
.content.house-unit .units .button-control button:hover {
    color: #10B981;
    background-color: transparent;
}


.content.house-unit .units .card-container {
    scroll-behavior: smooth;
    align-self: flex-end;

    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: 1200px;

    overflow-x: auto;
    padding-bottom: 24px;
}

.content.house-unit .units .card-container .card {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 300px;
    max-width: 300px;
    margin-right: 48px;
}
.content.house-unit .units .card-container .card:last-child {
    margin-right: 0;
}
.content.house-unit .units .card-container .card-img {
    position: relative;

    height: 280px;
    width: 100%;

    border-radius: 20px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.content.house-unit .units .card-container .card-img h6 {
    position: absolute;
    left: 16px;
    bottom: 12px;

    color: #fff;

    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: .05em;
}

.content.house-unit .units .card-container .card p {

    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 300;

    text-align: justify;
    text-align-last: center;
    line-height: 1.1em;

    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;
}

.content.house-unit .units .card-container .card .details {
    margin-top: 16px;
    max-width: 200px;
    margin-bottom: 18px;
}

.card > a {
    width: 100%;
    margin-top: auto;
}
.card .check-btn {
    width: 100%;
    padding: 4px 12px;
    border-radius: 500px;
    border: solid 2px #379144;

    color: #fff;
    background-color: #379144;
    
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;

}
.card .check-btn:active,
.card .check-btn:hover {
    background-color: transparent;
    color: #379144;
}

@media (max-width: 1400px) {
    .content.house-unit .units .card-container {
        width: 90%;
        margin-left: auto;
        margin-right: 24px;
    }
    .content.house-unit .units .button-control {
        left: 12px;
    }
}
@media (max-width: 768px) {
    .content.house-unit .units {
        margin-top: 90px;
    }
    .content.house-unit .units .button-control {
        left: 12px;
    }
    .content.house-unit .units .card-container {
        width: 90%;
        margin: auto;
    }
    .content.house-unit .header {
        box-sizing: border-box;
        padding-left: 24px;
        padding-right: 24px;
    }
    .content.house-unit .header p {
        max-width: 480px;
    }
}

@media (max-width: 576px) {
    .content.house-unit .header > div {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
    .content.house-unit .header {
        align-items: center;
        width: 100%;

        padding-left: 40px;
        padding-right: 40px;
    }
    .content.house-unit .header h2 {
        font-size: 32px;
        margin-bottom: 18px;
    }
    .content.house-unit .header p {
        text-align: center;

        max-width: 400px;
    }
    .content.house-unit .header > div .bar {
        display: none;
    }

    .content.house-unit .units {
        margin-top: 60px;
    }
    .content.house-unit .units .button-control {
        display: none;
        position: static;
        
        max-width: 240px;
        margin: auto;
        margin-bottom: 24px;
    }

    .content.house-unit .units .card-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: auto;

        overflow-x: hidden;
    }
    .content.house-unit .units .card-container .card {
        min-width: 280px;
        max-width: 280px;

        margin-bottom: 90px;
        margin-right: unset;
    }
    .content.house-unit .units .card-container .card-img {
        height: 240px;
    }
}
@media (max-width: 420px) {
    .content.house-unit .header {
        padding-left: 24px;
        padding-right: 24px;
    }
}
@media (max-width: 360px) {

}