.content.vicinity-map {
    height: calc(100% - 90px);
}
.vicinity-map-v2 {
    position: relative;
    display: flex;
    height: 100%;
    min-height: 600px;
    max-height: calc(100% - 90px);

    max-width: 1920px;
    margin: auto;

    padding: 24px 0;
    box-sizing: border-box;
}

.vicinity-map-v2 .map-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0 42px;

    margin: auto;
    min-width: 280px;
}
.vicinity-map-v2 .map-controls h4 {
    font-size: 20px;
    margin: 0 auto;
    margin-bottom: 24px;
}
.vicinity-map-v2 .map-controls .btn-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.vicinity-map-v2 .map-controls .btn-cont hr {
    width: 80%;
    border: solid 1px  #003262;
    border-radius: 12px;
}
.vicinity-map-v2 .map-controls .btn-cont .map-btn {
    color: #fff;
    background-color: #ba9b6a;
    border: solid 3px #ba9b6a;
    border-radius: 60px;

    padding: 12px 24px;
    margin: 8px 0;
    font-size: 16px;

    cursor: pointer;
}
.vicinity-map-v2 .map-controls .btn-cont .map-btn:hover {
    background-color: transparent;
    color: #ba9b6a;
}
.vicinity-map-v2 .map-controls .btn-cont .map-btn.active:hover {
    color: #fff;
}
.vicinity-map-v2 .map-controls .btn-cont .map-btn.active {
    border-color: #003262;
    background-color: #003262;
}

.vicinity-map-v2 .map-controls .btn-cont .map-btn.nearby {
}

.vicinity-map-v2 .map-body {
    position: relative;
    display: inline-block;
    margin: auto;
    margin-left: unset;

    max-width: 1920px;
    box-sizing: border-box;
    overflow: hidden;
}

.vicinity-map-v2 .map-body img.map-img-main {
    z-index: 100;
    max-width: 100%;
    max-height: calc(100vh - 120px);
}
.vicinity-map-v2 .map-body img.map-glow {
    z-index: 101;
    max-width: 100%;
    max-height: calc(100vh - 120px);

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    
    animation: mapGlow 2.2s ease-in-out forwards 1s;
    animation-iteration-count: 3;
    animation-play-state: paused;
}

@keyframes mapGlow {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.vicinity-map-v2 .map-body img.bldgs {
    z-index: 101;
    position: absolute;
    max-width: 100%;
    max-height: calc(100vh - 120px);

    top: 0;
    left: 0;

    opacity: 0;
    transform: translateY(-12px);
    
    transition: opacity 250ms ease-in, transform 250ms ease-in;
}
.vicinity-map-v2 .map-body img.bldgs.show {
    opacity: 1;
    transform: translateY(0);
}


.vicinity-map-v2 .map-nearby {
    z-index: 200;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: #fff;
    
    width: 100%;
    max-width: 480px;
    max-height: calc(100vh - 120px);

    background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/textured-bg-3.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    box-sizing: border-box;
    overflow-y: auto;

    opacity: 0;
    transform: translateX(100%);

    transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
}
.vicinity-map-v2 .map-nearby.show {
    opacity: 1;
    transform: translateX(0);
}

.vicinity-map-v2 .map-nearby h4 {
    margin: 42px 0;
    font-size: 24px;
    text-align: center;
}

.vicinity-map-v2 .map-nearby .establishment {
    margin-bottom: 72px;
}

.vicinity-map-v2 .map-nearby .establishment h5 {
    display: inline-block;
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    text-align: center;

    color: #fff;
    background-color: #ba9b6a;
    padding: 8px 16px;

    min-width: 160px;
}

.vicinity-map-v2 .map-nearby .establishment ul {
    padding-inline-start: 48px;
    padding-inline-end: 48px;
}

.vicinity-map-v2 .map-nearby .establishment ul li {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
}
.vicinity-map-v2 .map-nearby .establishment ul li small {
    font-weight: normal;
}


.vicinity-map-v2 .map-controls .btn-cont.tablet img.map-img-btn {
    width: 100%;
    max-width: 72px;
    margin-bottom: 16px;
}
.vicinity-map-v2 .map-controls .btn-cont.tablet img.map-img-btn:last-child {
    margin-bottom: 0;
}

.vicinity-map-v2 .map-nearby .close-nearby {
    position: absolute;
    top: 16px;
    right: 16px;
    max-width: 32px;

    cursor: pointer;
}
.vicinity-map-v2 .text-overlay.mobile {
    z-index: 200;
    position: fixed;
    top: 90px;
    left: 0;

    display: none;

    height: calc(100vh - 90px);
    width: 100vw;
    max-width: 100%;

    background-color: rgba(0, 50, 98, .9);
}
.vicinity-map-v2 .text-overlay.mobile .text-content {
    margin: auto;

    padding: 0 24px;
    max-width: 480px;
}
.vicinity-map-v2 .text-overlay.mobile .text-content * {
    color: #fff;
    text-align: center;
}
.vicinity-map-v2 .text-overlay.mobile .text-content h2 {
    font-size: 36px;
    margin: 0;
    margin-bottom: 20px;
}

.vicinity-map-v2 .text-overlay.mobile .text-content h4 {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.25em;
}
.vicinity-map-v2 .text-overlay.mobile .text-content > .instructions {
    margin-top: 42px;
}
.vicinity-map-v2 .text-overlay.mobile .text-content > .instructions p {
    margin: 8px;
    /* font-style: italic; */
}
.vicinity-map-v2 .text-overlay.mobile .text-content small {
    display: inline-block;
    width: 100%;

    font-size: 16px;
    letter-spacing: .04em;

    margin-top: 60px;
    opacity: 1;

    animation: textpulse 2s ease-in-out infinite;
}
@keyframes textpulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .1;
    }
    100% {
        opacity: 1;
    }
}

.vicinity-map-v2 .map-nearby.mobile {
    display: none;
} 
@media (min-width: 1280.98px) {
    .vicinity-map-v2 .map-controls .btn-cont.tablet {
        display: none;
    }
}
@media (max-width: 1280px) {
    .vicinity-map-v2 .map-controls .btn-cont.desktop {
        display: none;
    }
    .vicinity-map-v2 .map-controls {
        min-width: 0;
        padding: 0 24px;
    }
    .vicinity-map-v2 .map-controls h4 {
        display: none;
    }
    .vicinity-map-v2 .map-body {
        margin: auto 0;
        margin-right: 24px;
    }
}

@media (max-width: 768px) {
    .content.vicinity-map .vicinity-map-v2{
        touch-action: none;
    }
    .content.vicinity-map .text-section {
        display: none;
    }
    .vicinity-map-v2 {
        padding: 0;
    }
    .vicinity-map-v2 .map-controls {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        margin: auto 0;
        justify-content: center;

        padding: 0 16px;
    }
    .vicinity-map-v2 .map-body {
        margin: auto;
        max-height: calc(100vh - 90px);
    }
    .vicinity-map-v2 .map-controls .btn-cont.tablet img.map-img-btn {
        z-index: 200;
        max-width: 48px;
        background-color: rgba(255, 255, 255, .8);
        border: none;
        border-radius: 100px;
    }
    .vicinity-map-v2 .map-nearby.desktop {
        display: none;
    }
    .vicinity-map-v2 .map-nearby.mobile {
        display: block;
    } 
    .vicinity-map-v2 .map-nearby {
        z-index: 110;
        position: fixed;
        top: 90px;
        left: unset;
        bottom: unset;
        right: 0;
        width: 100%;

        max-height: calc(100vh - 90px);

        opacity: 0;
        transform: translateX(100%);
    }
    .vicinity-map-v2 .map-nearby.show {
        opacity: 1;
        transform: translateX(0);
    }
    
    .vicinity-map-v2 .map-body img.map-img-main,
    .vicinity-map-v2 .map-body img.bldgs, .vicinity-map-v2 .map-body img.map-glow {
        max-width: 1600px;
        max-height: unset;
    }
    .vicinity-map-v2 .text-overlay.mobile {
        display: flex;
    }
}
@media (max-width: 576px) {
    .content.vicinity-map {
        height: calc(100% - 72px);
    }
    .vicinity-map-v2 {
        height: 100%;
        /* max-height: calc(100% - 72px); */
    }
    .vicinity-map-v2 .map-body {
        height: 100%;
        /* max-height: calc(100% - 72px); */
    }
    .vicinity-map-v2 .map-body img.map-img-main, .vicinity-map-v2 .map-body img.bldgs, .vicinity-map-v2 .map-body img.map-glow {
        max-width: 1600px;
    }
    .vicinity-map-v2 .text-overlay.mobile {
        top: 72px;
        height: calc(100vh - 72px);
    }
    .vicinity-map-v2 .map-nearby {
        top: 72px;
        max-height: calc(100vh - 72px);
        max-width: 420px;
    }
}
@media (max-width: 425.98px) {
    .vicinity-map-v2 .map-controls {
        z-index: 200;
        position: fixed;
        top: unset;
        bottom: 40px;
        width: 100%;
        padding: 0 12px;
    }
    .vicinity-map-v2 .map-controls .btn-cont {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
    .vicinity-map-v2 .map-controls .btn-cont.tablet img.map-img-btn {
        margin-bottom: 0;
        background-color: rgba(255, 255, 255, 1);
    }
    .vicinity-map-v2 .map-body img.map-img-main, .vicinity-map-v2 .map-body img.bldgs, .vicinity-map-v2 .map-body img.map-glow {
        max-width: 1440px;
    }
}
