.content.houses {
    position: relative;
    /* background-color: #fff;

    background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/Aerialv2.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    scroll-behavior: smooth;
}
.content.houses::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    background: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/Aerialv2.jpg') no-repeat center;
    background-size: cover;
    will-change: transform;
    z-index: -1;
} 

.content.houses .main-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 480px;

    background-size: cover;
    background-position: 50% 70%;
    background-repeat: no-repeat;
}


.content.houses .main-img .bg-color {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(50, 50, 50, .6);
}
.content.houses .main-img div {
    text-align: center;
    z-index: 11;
}
.content.houses .main-img div img {
    max-width: 48px;
    margin-bottom: 8px;
}
.content.houses .main-img h2 {
    font-family: 'Optima';
    font-weight: bold;
    font-size: 48px;
    letter-spacing: .05em;

    color: rgba(255, 255, 255, 1);

    margin: unset;
    /* margin-top: 12px; */
}

.content.houses .main-img h2 span {
    font-size: 54px;
    font-family: 'Optima';
    font-weight: 500;
}



.content.houses .meet-calista {
    display: flex;
    max-width: 1400px;

    margin: 72px auto;
}
.content.houses .meet-calista .text-cont {
    display: flex;
    flex-direction: column;

    align-items: stretch;
    justify-content: center;

    min-height: 720px;
    width: 60%;
}
.content.houses .meet-calista .img-cont {
    align-self: stretch;
    width: 40%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.content.houses .meet-calista .text-cont .text {
    margin: 0 auto;
    max-width: 720px;

    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
}
.content.houses .meet-calista .text-cont .text hr {
    width: 80%;
    /* max-width: 320px; */
    border: 1px dashed rgba(50, 50, 50, .8);
    border-radius: 4px;
    
    margin: 24px auto;
}
.content.houses .meet-calista .text-cont .text h4 {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: #2f2f2f;
}
.content.houses .meet-calista .text-cont .additional-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    width: 100%;
    max-width: 720px;

    margin: 0 auto;
    margin-top: 8px;

    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
}
.content.houses .meet-calista .text-cont .additional-details .detail {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 12px 16px;
}
.content.houses .meet-calista .text-cont .additional-details .detail img {
    max-width: 36px;
    margin-bottom: 4px;
}
.content.houses .meet-calista .text-cont .additional-details .detail h4 {
    color: #2f2f2f;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 4px;
}
.content.houses .meet-calista .text-cont .additional-details .detail small {
    color: #4f4f4f;
    font-size: 14px;
    margin-top: auto;
}

.content.houses .meet-calista .text-cont h4,
.content.houses .meet-calista .text-cont h4 * {
    font-family: 'Optima';
}
.content.houses .meet-calista .text-cont h4 i {
    font-weight: 600;
    color: #003262;
}
.content.houses .meet-calista .text-cont h4 span {
    font-family: 'Optima', serif;
}

.content.houses .meet-calista .text-cont p {
    color: #2f2f2f;
    font-size: 18px;
    font-weight: 300;

    text-align: center;

    padding-left: 40px;
    padding-right: 40px;
}
.content.houses .meet-calista .text-cont h5 {
    font-size: 15px;
    font-weight: normal;
    font-style: italic;

    text-align: center;

    padding-left: 40px;
    padding-right: 40px;
}

.content.houses .meet-calista .text-cont .details {
    align-self: center;
    width: 100%;
    max-width: 320px;

    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 72px;
}
.content.houses .meet-calista .text-cont .btn-360 {
    align-self: center;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    border: solid 3px #003262;
    color: #fff;
    background-color: #003262;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px;
    letter-spacing: .0125em;

    margin-top: 42px;
    min-width: 240px;
    cursor: pointer;
}

.content.houses .meet-calista .text-cont .btn-360:hover,
.content.houses .meet-calista .text-cont .btn-360:active {
    background-color: transparent;
    /* border-color: #003262; */
    font-weight: bold;
    color: #003262;
}

.content.houses .banner-cont {
    position: relative;
    width: 100%;
    height: 360px;

    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}
.content.houses .banner-cont .bg-color {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(24, 53, 97, .75);
}

.content.houses .banner-cont {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
}

.content.houses .banner-cont h4 {
    z-index: 11;
    font-family: 'Optima';
    font-weight: 400;
    font-size: 32px;

    line-height: 1.2em;
    color: #fff;

    margin: 0;
}

.content.houses .banner-cont p {
    z-index: 11;
    font-weight: 200;
    font-size: 16px;
    color: #fff;
    line-height: 1.2em;

    margin-top: 12px;
}



.content.houses .house-images {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    min-height: calc(100vh - 90px);
    margin: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    box-sizing: border-box;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/textured-bg-3.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.content.houses .house-images .controller {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
}

.content.houses .house-images .controller button {
    font-size: 16px;
    font-weight: bold;

    min-width: 160px;

    color: #003262;
    border: solid 3px #003262;
    background-color: transparent;
    border-radius: 500px;
    padding: 8px;
    margin-left: 16px;

    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    /* margin-bottom: 12px; */
}

.content.houses .house-images .controller button.alt {
    color: #fff;
    border-color: #ba9b6a;
    /* outline: solid 4px #ba9b6a; */
    background-color: #003262;
}

.content.houses .house-images .controller button:first-child {
    margin-left: unset;
}


.content.houses .house-images .controller button .controller-icon {
    height: 20px;
    width: 20px;

}

.content.houses .house-images .controller button span {
    display: flex;
    align-items: center;

    justify-content: center;
}

.content.houses .house-images .controller button span i {
    margin-right: 4px;
}


.content.houses .house-images .controller button span i.exterior {
    content: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/exterior-icon.png');
}

.content.houses .house-images .controller button.alt span i.exterior {
    content: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/exterior-icon-alt.png');
}


.content.houses .house-images .controller button span i.interior {
    content: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/interior-icon.png');
}

.content.houses .house-images .controller button.alt span i.interior {
    content: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/interior-icon-alt.png');
}

.content.houses .house-images .controller button span i.floor1 {
    content: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/1stfloor-icon.png');
}

.content.houses .house-images .controller button.alt span i.floor1 {
    content: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/1stfloor-icon-alt.png');
}


.content.houses .house-images .img-cont {
    margin-top: 42px;
    max-width: 1400px;
}


.content.houses .house-images .img-cont .exterior-cont,
.content.houses .house-images .img-cont .interior-cont,
.content.houses .house-images .img-cont .floor1-cont {
    display: flex;
    flex-wrap: wrap;
    
    justify-content: center;
}

.content.houses .house-images .img-cont > div h6 {
    font-size: 60px;
    color: #003262;
    font-family: "Natalie";
}

.content.houses .house-images .img-cont .exterior-cont img,
.content.houses .house-images .img-cont .interior-cont img,
.content.houses .house-images .img-cont .floor1-cont img {
    max-width: 300px;

    /* border: solid 6px transparent; */
    /* border: solid 8px transparent; */
    margin: 18px;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 6px 8px rgba(25, 25, 25, .32);
}
.content.houses .house-images .img-cont .exterior-cont img.img-thumb:hover,
.content.houses .house-images .img-cont .interior-cont img.img-thumb:hover {
    /* border-color: #ba9b6a; */
    outline: solid 8px #ba9b6a;
}



.content.houses .floor-plans {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 40px;

    margin: auto;
    max-width: 1200px;
    box-sizing: border-box;
    min-height: 100%;
}
.content.houses .floor-plans h4 {
    font-size: 32px;
    font-weight: 600;
    font-family: 'Optima';
    text-align: center;
    
    /* margin-bottom: 32px; */
    margin: 0;
}

.content.houses .floor-plans hr {
    max-width: 480px;
    border: dashed 1px rgba(150, 150, 150, .8);
    border-radius: 500px;
}

.content.houses .floor-plans .img-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 20px;
}

.content.houses .floor-plans .img-cont .floor-plan {
    width: 100%;
    max-width: 360px;
    margin: auto 24px;
}

.content.houses .floor-plans .img-cont .floor-plan img {
    width: 100%;
    cursor: pointer;
}
.content.houses .floor-plans .img-cont .floor-plan img:hover {
    outline: solid 6px #ba9b6a;
}

.content.houses .floor-plans .img-cont .floor-plan h6 {
    text-align: center;

    font-size: 18px;
    font-weight: 300;
    color: #2f2f2f;
    margin: 0;
    margin-bottom: 16px;
}

@media (max-width: 1440px) {
    .content.houses .meet-calista {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 1200px) {
    .content.houses .meet-calista {
        padding: unset;
        margin: unset;
    }
}

@media (max-width: 992px) {
    .content.houses .meet-calista .text-cont {
        width: 60%;
    }
    .content.houses .meet-calista .img-cont {
        width: 40%;
    }

    .content.houses .meet-calista .text-cont p {
        font-size: 16px;
    }



    .content.houses .banner-cont {
        box-sizing: border-box;
        padding-left: 40px;
        padding-right: 40px;
    }

    .content.houses .house-images .img-cont .exterior-cont img,
    .content.houses .house-images .img-cont .interior-cont img,
    .content.houses .house-images .img-cont .floor1-cont img {
        max-width: 240px;
    }

    .content.houses .floor-plans .img-cont .floor-plan {
        margin-bottom: 42px;
    }
}

@media (max-width: 768px) {
    .content.houses .meet-calista .text-cont {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
    .content.houses .meet-calista .img-cont {
        display: none;
    }
    

    .content.houses .banner-cont h4 {
        font-size: 28px;
    }


    .content.houses .house-images {
        margin-top: 20px;
    }
}

@media (max-width: 576px) {
    .content.houses .main-img {
        height: 100vh;
        max-height: calc(100vh - 72px);    
    }

    .content.houses .main-img h2 {
        font-size: 42px;
    }
    .content.houses .main-img h2 span {
        font-size: 36px;
    }

    .content.houses .meet-calista .text-cont {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;

        justify-content: flex-start;
        min-height: 0;

        padding-top: 40px;
        padding-bottom: 60px;
    }


    .content.houses .house-images .img-cont {
        margin-top: 20px;
    }

    .content.houses .house-images .img-cont .exterior-cont img,
    .content.houses .house-images .img-cont .interior-cont img,
    .content.houses .house-images .img-cont .floor1-cont img {
        max-width: 180px;
        margin: 8px;
    }


    .content.houses .floor-plans h4 {
        font-size: 28px;
    }

    .content.houses .floor-plans {
        padding: 60px 20px;
    }
}

@media (max-width: 480px) {
    .content.houses .meet-calista .text-cont {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }

    .content.houses .banner-cont {
        padding-left: 24px;
        padding-right: 24px;

        height: 280px;
    }
    .content.houses .banner-cont h4 {
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .content.houses .meet-calista .text-cont p {
        padding-left: 12px;
        padding-right: 12px;
    }
    .content.houses .floor-plans .img-cont .floor-plan {
        margin: auto;
        margin-bottom: 42px;
    }
}
@media (max-width: 400px) {
    .content.houses .house-images .img-cont .exterior-cont img,
    .content.houses .house-images .img-cont .interior-cont img,
    .content.houses .house-images .img-cont .floor1-cont img {
        max-width: 280px;
        margin: 8px;
    }
}
@media (max-width: 375px) {
    .content.houses .meet-calista .text-cont .text hr {
        display: none;
    }
    .content.houses .meet-calista .text-cont p {
        text-align: justify;
        text-align-last: center;
    }
    .content.houses .meet-calista .text-cont h5 {
        padding-left: 12px;
        padding-right: 12px;
    }
    .content.houses .banner-cont h4 {
        font-size: 20px;
        padding-left: 12px;
        padding-right: 12px;

        line-height: 1.1em;
    }
    .content.houses .banner-cont p {
        font-size: 14px;
        padding-left: 12px;
        padding-right: 12px;

        margin-top: 24px;
    }
}
@media (max-width: 425px) {
    .content.houses .house-images .controller {
        /* flex-direction: column; */
    }
    .content.houses .house-images .controller button {
        min-width: 120px;
    }
    .content.houses .house-images .img-cont .exterior-cont img,
    .content.houses .house-images .img-cont .interior-cont img,
    .content.houses .house-images .img-cont .floor1-cont img {
        max-width: 160px;
    }
}
@media (max-width: 375px) {
    .content.houses .floor-plans .img-cont {
        flex-direction: column;
    }
    .content.houses .floor-plans .img-cont .floor-plan:first-child {
        margin-bottom: 24px;
    }
    .content.houses .house-images .img-cont .exterior-cont img,
    .content.houses .house-images .img-cont .interior-cont img,
    .content.houses .house-images .img-cont .floor1-cont img {
        max-width: 140px;
        margin: 2px;
    }
}


.floor-additional-details {
    margin: auto;
    margin-top: 60px;
    max-width: 280px;

    color: #2f2f2f;

    list-style-position: inside;
    padding-inline-start: 0;
    text-align: center;
}
.floor-additional-details {
    list-style-type: none;
    text-align: center;
}
.floor-additional-details li {
    margin-top: 4px;
}
.floor-additional-details h5 {
    font-size: 18px;
    margin: unset;
    margin-bottom: 12px;

    text-align: center;
}
.floor-additional-details span {
    font-style: italic;
}


.content.houses .img-cont .img-modal {
    z-index: 400;
    position: fixed;
    bottom: 0;
    left: 0;

    display: flex;
    width: 100%;
    height: 100vh;
    max-height: calc(100vh - 90px);
    /* background-color: rgba(5, 19, 41, 0.85); */
    background-color: rgba(25, 25, 25, .9);
}
.content.houses .img-cont .img-modal > div.close-div {
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
    
    height: 100%;
    width: 100%;
}
.content.houses .img-cont .img-modal > div.img-cont {
    display: flex;
    z-index: 201;
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 1600px;
    box-sizing: border-box;
}
.content.houses .img-cont.floorPlan .img-modal > div.img-cont {
    max-width: 900px;
}
.content.houses .img-cont .img-modal > div.img-cont > div {
    display: inline-block;
    position: relative;

    margin: auto;
}
.content.houses .img-cont .img-modal > div.img-cont .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;

    /* color: #ba9b6a; */
    outline: none;
    color: #003262;
    font-size: 2em; 
    cursor: pointer;
    max-width: 40px;

    box-shadow: none;
}
.content.houses .img-cont .img-modal > div.img-cont .close-btn:active {
    transform: scale(.9);
}
.content.houses .img-cont .img-modal > div.img-cont img {
    max-width: 100%;
    /* max-width: none; */
    max-height: calc(100vh - 140px);
    margin: 0;

    border: none;
    outline: none;
    /* border: solid 6px #ba9b6a; */
    border-radius: 0px;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .content.houses .img-cont .img-modal > div.img-cont {
        max-width: 1000px;
    }
}
@media (max-width: 1024px) {
    .content.houses .img-cont .img-modal > div.img-cont {
        margin: auto 20px;
    }
}
@media (max-width: 768px) {
    .content.houses .img-cont .img-modal > div.img-cont .close-btn {
        max-width: 32px;
        top: 8px;
        right: 8px;
    }
}
@media (max-width: 576px) {
    .content.houses .img-cont .img-modal {
        max-height: calc(100vh - 72px);
    }
    .content.houses .img-cont .img-modal > div.img-cont {
        margin: auto 8px;
    }
}
@media (max-width: 425px) {
    .content.houses .img-cont .img-modal > div.img-cont .close-btn {
        max-width: 28px;
        top: 6px;
        right: 6px;
    }
}
