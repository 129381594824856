.content.about {
    position: relative;
    background-color: #fff;
    
    overflow-y: auto;
    background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/Aerialv2.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.content.about .about-container {
    position: relative;

    background-size: 600px;
    background-repeat: no-repeat;
    background-position: bottom right;

    /* padding-bottom: 240px; */
}

/* 4Es Section */
.content.about .about-container .section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    height: calc(100vh - 90px);
    min-height: 900px;

    box-sizing: border-box;
    padding-top: 90px;

    margin: auto;
}
.content.about .about-container .section1 .text-header {
    max-width: 720px;
    /* margin-top: -90px; */
    margin-bottom: 32px;
}
.content.about .about-container .section1 .text-header * {
    text-align: center;
}


.content.about .about-container .section1 .text-header h2 {
    font-size: 48px;
    color: #003262;
}


.content.about .about-container .section1 .card-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    
    align-self: center;

    /* overflow-x: auto; */

    width: 100%;
    max-width: 1400px;

    padding-top: 40px;
    /* box-sizing: border-box; */
}
.content.about .about-container .section1 .card-container .card {
    display: flex;
    align-items: center;

    position: relative;
    width: 100%;
    min-width: 280px;
    max-width: 280px;

    height: 420px;

    border-radius: 12px;
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;

    margin: 24px 12px;

    transition: transform 400ms ease-in-out;
}
.content.about .about-container .section1 .card-container .card:hover {
    transform: translateY(-24px);
}


/* .content.about .about-container .section1 .card-container .card:last-child {
    margin-right: 0;
} */

.content.about .about-container .section1 .card-container .card img {
    z-index: 22;
    position: absolute;
    top: 16px;
    left: 18px;

    max-width: 40px;
}

.content.about .about-container .section1 .card-container .card p {
    z-index: 23;
    position: relative;
    color: #fff;

    font-family: 'Optima', serif;
    font-size: 32px;
    /* line-height: 1.25em; */
}

.content.about .about-container .section1 .card-container .card p b {
    font-family: 'Natalie';
    font-style: italic;
    font-size: 48px;

    font-weight: bold;
    letter-spacing: .1em;
} 

.content.about .about-container .section1 .card-container .card .color-bg {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 12px;
    background-color: rgba(0, 50, 98, .8);
    /* background-color: rgba(50, 139, 68, .85); */
}



/* About Us Section */

.content.about .about-container .section2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    min-height: calc(100vh - 90px);

    padding: 72px 0;
}
.content.about .about-container .section2 .text-header {
    position: relative;
    /* margin-top: -90px; */
    margin-bottom: 24px;
}
.content.about .about-container .section2 .text-header h2 {
    text-align: center;
    
    font-weight: 500;
    font-size: 48px;
    color: #003262;
    margin: 0;
}

.content.about .about-container .section2 .info-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    margin-top: 28px;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
}

.content.about .about-container .section2 .info-container .info-card {
    position: relative;

    width: 100%;
    max-width: 700px;

    margin-right: 40px;
}

.content.about .about-container .section2 .info-container .info-card:last-child {
    margin-right: 0;
}

.content.about .about-container .section2 .info-container .info-card .img-bg {
    height: 320px;
    width: 100%;

    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(155, 155, 155, .5);

    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}

.content.about .about-container .section2 .info-container .info-card .text-content {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 600px;

    min-height: 320px;
    
    margin: auto;
    margin-top: -80px;


    background-color: #fff;

    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(155, 155, 155, .75);
    padding: 24px 28px;
    border-radius: 12px;
}

.content.about .about-container .section2 .info-container .info-card .text-content h4 {
    font-size: 20px;
    margin: 0;
}

.content.about .about-container .section2 .info-container .info-card .text-content p {
    font-size: 15px;
    font-weight: 300;

    text-align: justify;
    text-align-last: left;

    line-height: 1.25em;
    color: #1f1f1f;
}

.content.about .about-container .section2 .info-container .info-card .text-content h6 {
    margin-top: 18px;
    margin-bottom: 12px;

    font-size: 15px;
    /* font-weight: 600; */
    color: #003262;
}

.content.about .about-container .section2 .info-container .info-card .text-content .more-info {
    width: 100%;
    max-width: 160px;
    align-self: flex-end;

    margin-top: auto;

    box-sizing: border-box;
    padding: 8px 16px;

    border: solid 4px #ba9b6a;
    border-radius: 500px;

    color: #fff;
    background-color: #ba9b6a;

    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    text-decoration: none;

    cursor: pointer;
}
.content.about .about-container .section2 .info-container .info-card .text-content .more-info:hover {
    background-color: #003262;
}
.content.about .about-container .section2 .info-container .info-card .text-content button:hover {
    background-color: transparent;
    color: #003262;
}


.content.about .about-container .section2.mobile {
    display: none;
    padding: 32px 20px;
    box-sizing: border-box;
}

.content.about .about-container .section2.mobile .info-card-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 420px;
    min-width: 280px;

    /* background-color: #fff; */
    box-sizing: border-box;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, .2); */

    overflow: hidden;
    /* border-radius: 8px; */
}
.content.about .about-container .section2.mobile .info-card-mobile img {
    width: 100%;
    border-radius: 4px;
}

.content.about .about-container .section2.mobile .info-card-mobile .text-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 92%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 16px 20px;
    margin: auto;
    margin-top: -32px;
    text-align: center;

    border-radius: 6px;
}

.content.about .about-container .section2.mobile .info-card-mobile .text-content .more-info {
    width: 100%;
    max-width: 200px;

    border-radius: 50px;
    box-sizing: border-box;
    padding: 8px;

    background-color: #ba9b6a;
    color: #fff;

    text-decoration: none;

    margin: 8px auto;
}
.content.about .about-container .section2.mobile .info-card-mobile .text-content .more-info:active {
    background-color: #003262;
}

.content.about .about-container .section2.mobile .info-card-mobile h4 {
    align-self: flex-start;
    text-align: center;
    /* font-style: italic; */
    font-size: 18px;
    /* text-transform: uppercase; */
    

    padding: 8px 20px;
    box-sizing: content-box;
    /* margin: 20px auto; */
    margin: 0 auto;
    /* margin-top: -36px; */
    /* box-shadow: 2px -4px 8px rgba(0,0,0,.5); */
}

.content.about .about-container .section2.mobile .info-card-mobile p {
    /* padding: 0 16px; */
    box-sizing: border-box;

    text-align: justify;
    text-align-last: center;
    line-height: 1.25em;
    font-size: 15px;

    margin: 0;
    margin-bottom: 24px;
}
.content.about .about-container .section2.mobile .info-card-mobile i {
    color: #003262;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 1.4em;
    
    margin: auto;
}

.content.about .about-container .section2.mobile .info-card-mobile:first-child {
    margin-top: .25em;
    margin-bottom: 6em;
}





@media (max-width: 1280px) {
    .content.about .about-container .section1 {
        box-sizing: border-box;
        padding-left: 40px;
        padding-right: 40px;
    }
    .content.about .about-container .section1 .card-container {
        padding: 12px 0;
    }
    .content.about .about-container .section2 .info-container .info-card .text-content {
        width: 90%;
    }
    .content.about .about-container .section2 .text-header h2 {
        font-size: 42px;
    }
    .content.about .about-container .section2 .info-container {
        margin-top: 16px;
    }
    .content.about .about-container .section2 .info-container .info-card .text-content .more-info {
        margin-top: 16px;
    }
    
}
@media (max-width: 992px) {
    .content.about .about-container .section1 .card-container {

        box-sizing: border-box;

        /* justify-content: flex-start; */
    }

    .content.about .about-container .section2 .info-container {
        flex-direction: column;
        align-items: center;

        padding-left: 20px;
        padding-right: 20px;
    }
    .content.about .about-container .section2 .info-container .info-card {
        width: 90%;
        margin-right: unset;
        margin-bottom: 72px;
    }
    .content.about .about-container .section2 .info-container .info-card .text-content {
        width: 92%;
    }
    .content.about .about-container .section2 .info-container .info-card .text-content {
        min-height: unset;
    }
}
@media (max-width: 576px) {
    .content.about {
        /* padding-top: 40px; */
    }
    .content.about .about-container {
        background-image: none !important;
    }

    .content.about .about-container .section1 {
        box-sizing: border-box;
        padding-left: unset;
        padding-right: unset;
    }

    .content.about .about-container .section1 .text-header {
        padding: 0 24px;
        
        max-width: 480px;
    }

    .content.about .about-container .section1 .card-container {
        width: 90%;
    }

    .content.about .about-container .section1 .card-container::-webkit-scrollbar {
        height: 3px;
    }

    .content.about .about-container .section2 .info-container {
        margin-top: 0;
    }
    .content.about .about-container .section2.desktop {
        display: none;
    }
    .content.about .about-container .section2.mobile {
        display: flex;
    }
    .content.about .about-container .section2 .text-header {
        margin-top: unset;
    }
    .content.about .about-container .section2 .info-container .info-card .text-content button{
        align-self: center;
        margin-top: 12px;

        max-width: 280px;
    }
    .content.about .about-container .section2 .info-container {
        flex-direction: column;
        align-items: center;

        padding-left: 0;
        padding-right: 0;
    }
    .content.about .about-container .section2 .info-container .info-card .img-bg {
        height: 200px;
    }
    .content.about .about-container .section2 .info-container .info-card .text-content {
        margin-top: -60px;
    }
    .content.about .about-container .section2 .text-header h2 {
        font-size: 32px;
    }
}