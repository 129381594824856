/* Properties Finder Landing Page */
.content.finder {
    /* background-color: #fff; */
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    box-sizing: border-box;
}
.content.finder-main {
    scroll-behavior: smooth;
    scroll-padding-top: 60px;
}
/* .content.finder-main::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    background: url("https://phirst-park.s3.ap-northeast-1.amazonaws.com/phirst-edition/Images/others/Aerialv2.png") no-repeat center;
    background-size: cover;
    will-change: transform;
    z-index: -1;
} */


.content.finder .wrapper {
    position: relative;
    margin-bottom: 24px;
    /* max-width: 1400px; */
}

.content.finder h1 {
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 48px;
}

.content.finder h1 i {
    text-transform: none;
    color: #379144;
}

.wrapper-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    max-width: 1400px;
    box-sizing: border-box;
    padding: 12px 24px;
}


.wrapper-buttons a {
    text-decoration: none;
    margin: 12px 16px;
}

.wrapper-buttons a:hover .img-button .foreColor {
    opacity: 0;
}

.wrapper-buttons a:hover .img-button .img-holder {
    transform: scale(1.2);
}

.wrapper-buttons .img-button {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 200px;
    height: 200px;

    
    border-radius: 8px;

    /* filter: grayscale(100); */
}

.wrapper-buttons .img-button .img-holder {
    z-index: 90;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url('../images/corin.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    border-radius: 8px;

    filter: grayscale(70%);
    transition: all 500ms ease-in-out;
}

.wrapper-buttons .img-button h4 {
    z-index: 101;
    color: #fff;
    font-size: 28px;
    text-align: center;

    letter-spacing: .075em;

    max-width: 100%;
}

.wrapper-buttons .img-button .foreColor {
    z-index: 100;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: .32;

    width: 100%;
    height: 100%;

    border-radius: 8px;
    transition: all 500ms ease-in-out;
}


@media (max-height: 768px) {
    .content.finder {
        justify-content: flex-start;
    }
}


/* Properties Finder Main Page */
.content.finder-main {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: space-around; */
}



.content.finder-main .finder-wrapper {
    display: flex;
    margin: auto;
    flex-direction: row;

    width: 100vw;
    max-width: 100%;

    height: 100vh;
    min-height: calc(100vh - 90px);

    box-sizing: border-box;
    padding: 60px 24px;
}


.content.finder-main > .text-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 40px;

    box-sizing: border-box;
    width: 100%;
    min-height: 600px;

    background: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-main-bg.jpg") no-repeat center;
    background-size: cover;
    background-position: center 75%;
    background-attachment: fixed;
}
.content.finder-main > .text-content * {
    z-index: 101;
    color: #fff;
}
.content.finder-main > .text-content > .contbg {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 50, 98, .75);
}
.content.finder-main > .text-content h2 {
    font-size: 42px;
    margin-bottom: 12px;
}
.content.finder-main > .text-content h4 {
    font-size: 18px;
    margin: unset;
    max-width: 720px;
    text-align: center;

    line-height: 1.4em;
}
.content.finder-main > .text-content .btn-cont {
    display: flex;
    margin-top: 60px;
}
.content.finder-main > .text-content .btn-cont a {
    text-decoration: none;
}
.content.finder-main > .text-content .btn-cont h6:first-child {
    /* margin-right: 24px; */
}
.content.finder-main > .text-content .btn-cont h6 {
    font-size: 16px;

    border-radius: 500px;

    border: solid 4px #ba9b6a;
    background-color: #ba9b6a;
    color: #fff;

    padding: 8px 24px;
    cursor: pointer;

    user-select: none;
    margin: unset;
}
.content.finder-main > .text-content .btn-cont h6:hover {
    background-color: #003262;
}

.content.finder-main .finder-wrapper .text-content h2 {
    color: #003262;
    font-size: 42px;
    margin-bottom: 12px;
}
.content.finder-main .finder-wrapper .text-content h4.desc {
    font-size: 18px;
    /* font-style: italic; */
    font-weight: normal;

    margin-bottom: 32px;
}

.content.finder-main .finder-wrapper .text-content .btn-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 480px;
}
.content.finder-main .finder-wrapper .text-content h6 {
    font-size: 16px;
    /* font-style: italic; */
}
.content.finder-main .finder-wrapper .text-content h6#viewEstablishment {
    
}

.content.finder-main .finder-wrapper .text-content h6.hide {
        
}


.content.finder-main .finder-wrapper .text-content h6:hover {
    /* border-color: #003262; */
    background-color: #003262;
}

.content.finder-main .finder-wrapper .other-content {
    position: relative;
    min-width: 100%;
    box-sizing: border-box;
    /* padding: 20px; */

    /* background-color: #ba9b6a; */
}
.content.finder-main .finder-wrapper .establishments-cont {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: flex-start;


    /* background-color: #ba9b6a; */
    width: 100%;
    height: 100%;
    min-height: 100%;

    border-radius: 4px;

    overflow: hidden;
    scroll-behavior: smooth;

    /* color: #2f2f2f; */
    color: #fff;
}
.content.finder-main .finder-wrapper .control-cont {
    z-index: 20;
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content.finder-main .finder-wrapper .control-cont .control {
    display: flex;
    /* position: absolute;
    top: 12px;
    right: 12px; */
}
.content.finder-main .finder-wrapper .control-cont .control a {
    
    /* height: 40px;
    width: 40px; */

    /* border: solid 2px #003262; */

    cursor: pointer;
}
.content.finder-main .finder-wrapper .control-cont .control a.disabled {
    pointer-events: none;
}
.content.finder-main .finder-wrapper .control-cont .control a:first-child {
    margin-right: 8px;
}
.content.finder-main .finder-wrapper .control-cont .control a i {
    display: flex;
    margin: auto;
    font-size: 2.5rem;
    line-height: 1em;

    border: solid 3px #fff;
    border-radius: 500px;
    
    color: #003262;
    background-color: #fff;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, .25);
}
.content.finder-main .finder-wrapper .control-cont .control a:hover i {
    
    border-color: #ba9b6a;
    color: #fff;
    background-color: #003262;
}


.content.finder-main .finder-wrapper .establishments-cont .establishment {
    position: relative;
    display: flex;
    min-width: 100%;
    min-height: 100%;

    box-sizing: border-box;
    padding: 24px;

    overflow: hidden;
}
.content.finder-main .finder-wrapper .establishments-cont .establishment.tourist {
    background-image: linear-gradient(135deg, #ba9b6a 25%, #fff);
}
.content.finder-main .finder-wrapper .establishments-cont .establishment.school .text-content {
    margin-top: -24px;
    margin-left: -24px;
    padding: 24px;
    padding-right: 100px;
    padding-bottom: 100px;

    border-right: solid 4px #fff;
    border-bottom: solid 16px #fff;

    background-color: #003262;
    border-bottom-right-radius: 500px;
}

.content.finder-main .finder-wrapper .establishments-cont .establishment .img-cont {
    z-index: 1;
    display: flex;

    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    
    overflow: hidden;
}

.content.finder-main .finder-wrapper .establishments-cont .establishment .img-cont img {
    width: 100%;
    /* margin-top: auto; */
    /* margin-left: auto; */
}

.content.finder-main .finder-wrapper .establishments-cont .establishment.transpo .img-cont {
    justify-content: center;
}
.content.finder-main .finder-wrapper .establishments-cont .establishment.transpo .img-cont img {
    width: unset;
}

.content.finder-main .finder-wrapper .establishments-cont .establishment .img-cont.tourist {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    right: -60px;

    border-top: solid 12px #fff;
    border-left: solid 12px #fff;
    
    border-top-left-radius: 500px;
    max-width: 600px;
    height: unset;
    overflow: hidden;
}
.content.finder-main .finder-wrapper .establishments-cont .establishment .img-cont.tourist img {
    max-height: 480px;
    height: 100%;
}


.content.finder-main .finder-wrapper .establishments-cont .establishment > div.text-content {
    z-index: 2;
    /* background-color: #ba9b6a; */
    align-self: flex-start;
}

.content.finder-main .finder-wrapper .establishments-cont .establishment h4 {
    font-size: 28px;
    letter-spacing: .05rem;
    text-transform: uppercase;

    margin-bottom: 16px;
}

.content.finder-main .finder-wrapper .establishments-cont .establishment ul {
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
    width: 100%;
    max-width: 100%;
    /* height: 100%;
    max-height: 420px; */

    list-style-type: circle;
    /* list-style-position: inside; */

    margin: 0;


    /* columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    column-gap: 20px;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px; */


    box-sizing: border-box;
    padding-inline-start: 24px;
    padding-inline-end: 24px;
}
.content.finder-main .finder-wrapper .establishments-cont .establishment ul li {
    margin-bottom: 16px;
    max-width: 360px;
}
.content.finder-main .finder-wrapper .establishments-cont .establishment ul li p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.content.finder-main .finder-wrapper .establishments-cont .establishment ul li i {
    font-size: 14px;
    font-weight: normal;
}


/* Side Control */

.content.finder-main .finder-wrapper .side-controls {
    width: 100%;
    max-width: 220px;

    display: none;
    /* display: flex; */
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    
}

.content.finder-main .finder-wrapper .side-controls a {
    text-decoration: none;
}


.content.finder-main .finder-wrapper .side-controls .side-btn {
    position: relative;
    width: 100px;
    height: 100px;

    background-color: transparent;
    border: none;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px auto;

    cursor: pointer;
}

.content.finder-main .finder-wrapper .side-controls .side-btn:hover .btn-image {
    filter: grayscale(100%) brightness(32%);
}

.content.finder-main .finder-wrapper .side-controls .side-btn h6 {
    z-index: 92;
    font-size: 14px;
    color: #fff;

    letter-spacing: .1em;
    word-break: break-all;
}

.content.finder-main .finder-wrapper .side-controls .side-btn .btn-image {
    z-index: 90;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    border: none;
    border-radius: 8px;

    background-image: url('../images/corin.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    filter: grayscale(100%) brightness(60%);

    transition: all 200ms ease-in-out;
}


/* Finder Content */
.content.finder-main .finder-wrapper .finder-content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-self: flex-start;

    margin: auto;
    width: 100%;
    max-width: 1100px;

    border-radius: 8px;
    box-sizing: border-box;

    overflow: hidden;

    scroll-behavior: smooth;
}

.content.finder-main .finder-wrapper .finder-content .nearby-toggle {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 301;
}
.content.finder-main .finder-wrapper .finder-content .nearby-toggle .nearby-icon {
    display: none;
    border: solid 4px #fff;
    background-color: #fff;

    padding: 6px;
    border-radius: 500px;

    width: 24px;
    cursor: pointer;
}
.content.finder-main .finder-wrapper .finder-content .nearby-toggle .nearby-icon-alt {
    display: none;
    border: solid 4px #ba9b6a;
    background-color: #003262;

    padding: 6px;
    border-radius: 500px;

    width: 24px;
    cursor: pointer;
}

.content.finder-main .finder-wrapper .finder-content .nearby-toggle .nearby-icon.show,
.content.finder-main .finder-wrapper .finder-content .nearby-toggle .nearby-icon-alt.show {
    display: block;
}


@media (max-width: 1200px) {
    .content.finder-main .finder-wrapper {
        flex-direction: column;
        padding-bottom: 40px;
    }
    .content.finder-main .finder-wrapper > .text-content {
        margin: auto;
        align-items: center;
        text-align: center;

        padding-bottom: 24px;
    }
    .content.finder-main .finder-wrapper .finder-content {
        margin: auto;
        max-width: 900px;
    }
    .content.finder-main .finder-wrapper .text-content .btn-cont {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .content.finder-main .finder-wrapper {
        padding: 0;
    }
    .content.finder-main .finder-wrapper .finder-content {
        flex-wrap: wrap;
        margin: 0;
    }
    .additional-content {
        display: none !important; 
    }
    .content.finder-main .finder-wrapper .finder-content .nearby-toggle {
        display: none;
    }
}
@media (max-width: 576px) {
    .content.finder-main .finder-wrapper .finder-content {
        padding: 40px 16px;
    }
    .content.finder-main .finder-wrapper {
        align-items: flex-start;
    }
    .content.finder-main > .text-content h2 {
        font-size: 36px;
    }
    .content.finder-main > .text-content h4 {
        font-size: 18px;
    }
}
@media (max-width: 425px) {
    .content.finder-main .finder-wrapper {
        height: 100vh;
        max-height: calc(100vh - 72px);
    }
    .content.finder-main .finder-wrapper .finder-content {
        display: flex;
        flex-direction: column-reverse;

        /* margin: auto; */
    }
}