/* Amenities Page */
.content.amenities {
    display: flex;
    align-items: center;
    
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/amenities-bg.jpg');

    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}
.content.amenities > div:last-child {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    overflow-y: auto;
    background-color: rgba(255, 255, 255, .8);

    box-sizing: border-box;
    padding: 2px;
    overflow-x: hidden;
}
.content.amenities .main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto;
    width: 100vw;
    max-width: 1600px;

    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
}

.content.amenities .text-container {
    display: flex;
    flex-direction: column;

    align-self: flex-start;

    width: 100%;
    max-width: 720px;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;

    /* opacity: 0; */

    /* transition: opacity 400ms ease-in-out; */
}

.content.amenities .text-container .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: -60px;
    margin-bottom: 24px;
}

.content.amenities .text-container p {
    font-size: 20px;
    line-height: 1.25em;
}

.content.amenities .text-container .header h2 {
    font-family: 'Optima';
    font-size: 48px;
    font-weight: 600;

    color: #003262;
    margin: unset;
}
.content.amenities .text-container .header .bar {
    width: 100%;
    max-width: 0;
    height: 8px;

    margin-right: 0;
    background-color: #003262;

    animation: growBar 1200ms ease-in-out forwards 500ms;
}
@keyframes growBar {
    0% {
        max-width: 0;
    }
    100% {
        max-width: 160px;
        margin-right: 16px;
    }
}
.content.amenities .text-container .house-detail {
    width: 100%;
    max-width: 360px;
    margin-top: 24px;
}
.content.amenities .text-container .more-info {
    color: #fff;
    background-color: #10B981;

    border-radius: 500px;
    border: solid 2px #10B981;

    width: 100%;
    max-width: 180px;

    font-size: 18px;
    padding: 4px 12px;

    margin-top: 40px;
    cursor: pointer;
}
.content.amenities .text-container .more-info:hover {
    color: #10B981;
    border-color: #10B981;
    background-color: transparent;
}


.content.amenities .img-container {
    position: relative;
    align-self: stretch;
    display: flex;  
}
.content.amenities .img-container > .container1 div.img-btn,
.content.amenities .img-container > .container2 div.img-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    overflow: hidden;
}

.content.amenities .img-container > .container1 div.img-btn > div,
.content.amenities .img-container > .container2 div.img-btn > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 500ms ease-in-out;

}
.content.amenities .img-container > .container1 div.img-btn.border-highlight,
.content.amenities .img-container > .container2 div.img-btn.border-highlight {
    outline: 8px solid #ba9b6a;
}

.content.amenities .img-container > .container1 div.img-btn:hover > div,
.content.amenities .img-container > .container2 div.img-btn:hover > div {
    transform: scale(1.2);
}
.content.amenities .img-container > .container1 div.img-btn.border-highlight > div {
    transform: scale(1.2);
}

.content.amenities .img-container > .container1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 720px;
}
.content.amenities .img-container > .container2 {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-left: 24px;
}

.content.amenities .img-container > .container1 div.img-btn {
    height: 180px;
    width: 180px;
    
    margin-left: 24px;
    /* margin-bottom: 24px; */
    background-color: #fff;
}
.content.amenities .img-container > .container1 div.img-btn:first-child {
    margin-left: unset;
}
/* .content.amenities .img-container .img1 {
    height: 240px;
    width: 280px;
}
.content.amenities .img-container .img2 {
    height: 200px;
    width: 420px;

    margin-top: 24px;
}
.content.amenities .img-container .img3 {
    height: 240px;
    width: 280px;
    margin-left: 16px;
}
.content.amenities .img-container .img4 {
    height: 280px;
    width: 320px;

    margin-top: 24px;
} */


.content.amenities .img-container > .container1 .btn-cont {
    margin-left: 24px;
}
.content.amenities .img-container > .container1 .btn-cont:first-child {
    margin-left: 0;
}
.content.amenities .img-container > .container1 .img-btn-text {
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    
    margin: 16px 0px 24px 0px;
}


.content.amenities .img-container .img1 > div,
.content.amenities .img-container .img2 > div,
.content.amenities .img-container .img3 > div,
.content.amenities .img-container .img4 > div,
.content.amenities .img-container .img5 > div,
.content.amenities .img-container .img6 > div,
.content.amenities .img-container .img7 > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.content.amenities .img-container .note {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -36px;
    left: -8px;
}
.content.amenities .img-container .note h4 {
    color: #1f1f1f;
    font-size: 14px;
    font-style: italic;

    margin: 0;

}



@media (max-width: 1440px) {
    .content.amenities .img-container > .container1 div.img-btn {
        height: 150px;
        width: 142px;
    }
}
@media (max-width: 1280px) {
    .content.amenities .text-container {
        max-width: 600px;
        align-self: center;

        margin-top: -200px;
    }
    .content.amenities .text-container .house-detail {
        max-width: 280px;
    }

    .content.amenities .text-container .header {
        margin-bottom: 28px;
    }
    .content.amenities .text-container .header h2 {
        font-size: 40px;
    }

    .content.amenities .img-container {
        /* min-height: 480px;     */
    }
    .content.amenities .img-container > .container1 {
        justify-content: flex-end;
        margin-right: 28px;
    }
    
    .content.amenities .img-container > .container1 div.img-btn {
        height: 140px;
        width: 140px;
    }

    .content.amenities .img-container .note {
        left: unset;
        right: 28px;
    }

    .content.amenities .img-container .img1 {
        height: 140px;
        width: 160px;
    }

    .content.amenities .img-container .img2 {
        height: 120px;
        width: 240px;
    }

    .content.amenities .img-container .img3 {
        height: 140px;
        width: 180px;
    }

    .content.amenities .img-container .img4 {
        height: 180px;
        width: 220px;
    }

}

@media (max-width: 992px) {
    .content.amenities > div {
        align-items: flex-start;
    }
    .content.amenities .main {
        flex-direction: column-reverse;
        margin: unset;
    }
    .content.amenities .text-container {
        align-items: center;
        padding-bottom: 40px;
        margin: auto;

        margin-top: 40px;
    }
    .content.amenities .text-container .header .bar {
        display: none;
    }
    .content.amenities .text-container .header {
        margin: auto;
        margin-bottom: 24px;
    }

    .content.amenities .text-container p {
        text-align: justify;
        text-align-last: center ;
    }

    .content.amenities .img-container {
        width: 100%;    
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 90px;
        min-height: unset;
    }

    .content.amenities .img-container > .container1, 
    .content.amenities .img-container > .container2 {
        justify-content: space-evenly;
        flex-direction: row;
        max-width: unset;

        margin-right: unset;
    }

    .content.amenities .img-container > .container2 {
        margin: unset;
    }
    .content.amenities .img-container > .container1 div.img-btn:first-child {
        margin: 12px;
    }
    .content.amenities .img-container > .container1 div.img-btn {
        height: 100px;
        width: 100px;

        margin: 12px;
    }


    .content.amenities .img-container .note {
        top: -24px;
        left: 0;
        right: 0;
    }

    .content.amenities .img-container .img1,
    .content.amenities .img-container .img2,
    .content.amenities .img-container .img3,
    .content.amenities .img-container .img4 {
        height: 100px;
        width: 100px;


        margin: 12px;

    }
}
@media (max-width: 768px) {
    .content.amenities {
        background: #fff;
    }
    .content.amenities > div:last-child {
        display: none;
    }
    .content.amenities .text-container .header h2 {
        font-size: 32px;
    }

    .content.amenities .img-container {
        justify-content: center;
    }
    .content.amenities .img-container > .container1 {
        justify-content: flex-start;
        flex-wrap: initial;
        overflow-x: auto;
        width: 100%;
        max-width: 600px;

        box-sizing: border-box;
        padding: 16px 4px;
        
    }
    .content.amenities .img-container > .container1::-webkit-scrollbar {
        height: 3px;
    }

    .content.amenities .img-container > .container1 div.img-btn {
        height: 90px;
        width: 90px;
        min-width: 90px;

        margin: unset;
        margin-right: 12px;
    }
    .content.amenities .img-container > .container1 div.img-btn:first-child {
        margin: unset;
        margin-right: 12px;
    }
    .content.amenities .img-container > .container1 div.img-btn:last-child {
        margin-right: unset;
    }

    .content.amenities .img-container > .container1 div.img-btn.border-highlight,
    .content.amenities .img-container > .container2 div.img-btn.border-highlight {
        outline: 4px solid #248cee;
    }
}
@media (max-width: 576px) {
    .content.amenities .text-container {
        padding-left: 12px;
        padding-right: 12px;
    }
    .content.amenities .text-container p {
        font-size: 16px;
    }

    .content.amenities .text-container .header h2 {
        font-size: 28px;
    }

    .content.amenities .img-container > .container1 div.img-btn {
        height: 80px;
        width: 80px;
    } 
}
@media (max-width: 420px) {
    .content.amenities .img-container .img1,
    .content.amenities .img-container .img2,
    .content.amenities .img-container .img3,
    .content.amenities .img-container .img4 {
        height: 120px;
        width: 120px;


        margin: 12px;

    }
}


/* Amenities Mobile */

.mobile-content {
    position: relative;
    width: 100vw;
    height: 100vh;
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    overflow-x: hidden;

    scroll-behavior: smooth;
}
.mobile-content .main-section-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-height: calc(100vh - 90px);
    
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/amenities-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mobile-content .main-section-mobile h2 {
    z-index: 101;
    font-size: 42px;
    color: #fff;
    margin: 0;
}

.mobile-content .main-section-mobile p {
    z-index: 101;
    padding: 0 40px;
    width: 100%;
    max-width: 480px;

    text-align: center;

    color: #fff;
    font-size: 18px;
    box-sizing: border-box;
}
.mobile-content .main-section-mobile a.redirect {
    z-index: 101;
    display: inline-block;
    background-color: #ba9b6a;
    color: #fff;

    font-weight: bold;
    text-align: center;
    text-decoration: none;
    width: 100%;
    max-width: 140px;
    border-radius: 100px;

    padding: 12px;

    margin-top: 24px;
}

.mobile-content .main-section-mobile .color-overlay {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 50, 98, .8);
}
.mobile-content .amenity-mobile {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 576px;
    height: 320px;
    margin: 24px auto;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}
.mobile-content .amenity-mobile .color-overlay {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 50, 98, .2);
    transition: all 400ms ease-in-out;
}
.mobile-content .amenity-mobile.show .color-overlay {
    background-color: rgba(0, 50, 98, .9);
}

.mobile-content .amenity-mobile h4 {
    z-index: 101;
    display: inline-block;
    position: absolute;
    top: 16px;

    margin: 0;
    background-color: #ba9b6a;
    color: #fff;
    font-size: 20px;
    text-align: center;

    padding: 8px;
    width: 100%;
    max-width: 200px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .5);
    transition: all 400ms ease-in-out;
}
.mobile-content .amenity-mobile:nth-child(even) h4 {
    left: 0;
}
.mobile-content .amenity-mobile:nth-child(odd) h4 {
    right: 0;
}

.mobile-content .amenity-mobile.show h4 {
    box-shadow: 0 0 12px rgba(255, 255, 255, .5);
}

.mobile-content .amenity-mobile p {
    z-index: 101;
    margin: auto;
    padding: 24px;
    text-align: center;
    width: 100%;
    max-width: 420px;
    color: #fff;

    opacity: 0;
    transition: all 400ms ease-in-out;
}
.mobile-content .amenity-mobile.show p {
    opacity: 1;
}
.mobile-content .amenity-mobile .info-icon {
    z-index: 102;
    position: absolute;
    bottom: 16px;
    right: 16px;
    align-self: flex-start;
    max-width: 32px;
    background-color: #ba9b6a;
    border-radius: 64px;
    
    transition: all 24ms ease-in-out;
}
.mobile-content .amenity-mobile .info-icon:active {
    transform: scale(.95);
}

@media (min-width: 769px) {
    .mobile-content {
        display: none;
    }
}

@media (max-width: 576px) {
    .mobile-content {
        max-height: calc(100vh - 72px);
    }
    .mobile-content .main-section-mobile {
        max-height: calc(100vh - 72px);
    }
    .mobile-content .amenity-mobile {
        margin: auto;
    }
}

@media (max-width: 375.98px) {
    .mobile-content .main-section-mobile p {
        font-size: 16px;
        line-height: 1.2em;
    }
}