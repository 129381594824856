.content.vicinity-map {
    scroll-behavior: smooth;
    overflow-x: hidden;
}
.content.vicinity-map .text-section {
    position: relative;
    display: flex;
    width: 100%;

    height: 100vh;
    max-height: 600px;

    background: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-main-bg.jpg") no-repeat center;
    background-size: cover;
    background-attachment: fixed;
}

.content.vicinity-map .text-section .contbg {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 50, 98, .75);
}

.content.vicinity-map .text-section .text-cont {
    display: flex;
    flex-direction: column;
    z-index: 101;
    margin: auto;
    max-width: 720px;
}
.content.vicinity-map .text-section .text-cont h2 {
    text-align: center;
    font-size: 42px;
    color: #fff;

    margin: 0;
    margin-bottom: 12px;
}
.content.vicinity-map .text-section .text-cont h4 {
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: 0;
}

.content.vicinity-map .text-section .text-cont .btn-cont {
    display: inline-flex;
    margin: auto;
    margin-top: 42px;
}
.content.vicinity-map .text-section .text-cont .btn-cont a {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    color: #fff;
    background-color: #ba9b6a;

    padding: 12px;
    min-width: 200px;

    border-radius: 100px;
}

.content.vicinity-map .map-section {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
    background-color: #fff;

    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
}

.content.vicinity-map .map-section .map-location-control {
    display: inline-flex;
    
    margin: 0 auto;
    margin-top: 20px;
    
    padding: 24px 0;
}
.content.vicinity-map .map-section .map-location-control button {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    
    min-width: 120px;

    color: #fff;
    border: solid 3px #ba9b6a;
    background-color: #ba9b6a;

    margin-right: 16px;

    padding: 8px;

    border-radius: 60px;
    cursor: pointer;
}
.content.vicinity-map .map-section .map-location-control button.selected {
    background-color: #003262;
}
.content.vicinity-map .map-section .map-location-control button:last-child {
    margin-right: 0;
}

.content.vicinity-map .map {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    max-height: 640px;
    max-width: 1200px;

    margin: 0 auto;
    margin-top: 0px;
    overflow: hidden;

    border-radius: 12px;
}

.content.vicinity-map .map .map-content {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    
    overflow: hidden;

    scroll-behavior: smooth;
}
.content.vicinity-map .map .map-content .img-cont {
    position: relative;
    min-width: 1920px;
    max-width: 1920px;

    overflow: hidden;
}
.content.vicinity-map .map .map-content .img-cont img {
    margin: auto;
    width: 100%;
}
.content.vicinity-map .map .map-content .img-cont img.img-overlay {
    z-index: 300;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateY(12px);
    transition: all 400ms ease-in-out;
}
.content.vicinity-map .map .map-content .img-cont img.img-overlay.show {
    opacity: 1;
    transform: translateY(0px);
}

.content.vicinity-map .map .map-control {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    min-width: 100%;
    height: 100%;
}

.content.vicinity-map .map .map-control button {
    position: absolute;
    z-index: 300;


    height: 48px;
    width: 48px;
    
    border: solid 4px #fff;
    border-radius: 60px;
    
    cursor: pointer;
    
    font-size: 1.5em;

    color: #fff;
    background-color: #ba9b6a;
}

.content.vicinity-map .map .map-control button:hover {
    border-color: #ba9b6a;
    background-color: #003262;
}
.content.vicinity-map .map .map-control button:active {
    transform: scale(.95);
}

.content.vicinity-map .map .map-control .left-control {
    top: 0;
    bottom: 0;
    left: 12px;
    margin: auto 0;

    padding-top: 4px;
    padding-right: 12px;
}
.content.vicinity-map .map .map-control .right-control {
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto 0;

    padding-top: 4px;
    padding-left: 10px;
}
.content.vicinity-map .map .map-control .top-control {
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.content.vicinity-map .map .map-control .bottom-control {
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;

    padding-top: 8px;
}


.content.vicinity-map .map-section .nearby-redirect {
    margin: 0 auto;
    padding: 24px 0px;
}
.content.vicinity-map .map-section .nearby-redirect a {
    color: black;
    font-size: 14px;

    text-decoration: none;

    border-bottom: solid 1px black;
}
.content.vicinity-map .map-section .nearby-redirect a:hover {
}

.content.vicinity-map .nearby-establishments {
    width: 100%;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/textured-bg-3.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}
.content.vicinity-map .establishment-header {
    display: flex;
    width: 100%;
    color: #fff;
    background-color: #003262;
    
    min-height: 120px;
}

.content.vicinity-map .establishment-header h3 {
    font-size: 28px;
    text-align: center;
    margin: auto;
}
.content.vicinity-map .nearby-establishments .cont {
    width: 100%;
    max-width: 1200px;

    margin: auto;
    padding-top: 24px;
    padding-bottom: 60px;

    box-sizing: border-box;
}
.content.vicinity-map .nearby-establishments .establishment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: relative;
    width: 100%;
    margin-bottom: 24px;

    padding: 0 24px;
    box-sizing: border-box;
}

.content.vicinity-map .nearby-establishments .establishment h4 {
    display: inline-block;
    font-size: 24px;

    /* text-align: center; */
}
.content.vicinity-map .nearby-establishments .establishment ul {
    /* list-style-position: inside; */
    list-style-type: disc;
    column-count: 3;
    width: 100%;
}
.content.vicinity-map .nearby-establishments .establishment ul li {
    margin-bottom: 12px;
    max-width: 320px;
}
.content.vicinity-map .nearby-establishments .establishment ul li small {
    font-style: italic;
    font-size: 14px;
    color: #4f4f4f;
}
.content.vicinity-map .nearby-establishments .establishment.tourist ul {
    min-height: 160px;
}

.content.vicinity-map .map .map-location-control-mobile {
    display: none;
    justify-content: space-between;
    z-index: 301;
    position: absolute;
    bottom: 20px;
}
.content.vicinity-map .map .map-location-control-mobile.left {
    left: 12px;
}
.content.vicinity-map .map .map-location-control-mobile.right {
    right: 12px;
}

.content.vicinity-map .map .map-location-control-mobile button {
    position: relative;
    border: none;
    background-color: transparent;

    height: 60px;
    width: 60px;
}
.content.vicinity-map .map .map-location-control-mobile button:first-child {
    margin-right: 8px;
}

.content.vicinity-map .map .map-location-control-mobile.top {
    top: 12px;
    bottom: unset;
    right: 12px;
}
.content.vicinity-map .map .map-location-control-mobile.top button {
    margin-right: 0;
}

.content.vicinity-map .map .map-location-control-mobile button img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
}
.content.vicinity-map .map .map-location-control-mobile button img.show {
    opacity: 1;
}


.content.vicinity-map .map-section .map-instructions {
    position: absolute;
    display: none;

    z-index: 500;
    height: 100%;
    width: 100%;

    background-color: rgba(0, 50, 98, .75);
}

.content.vicinity-map .map-section .map-instructions.hide {
    display: none;
}

.content.vicinity-map .map-section .map-instructions .instructions {
    margin: auto;
    color: #fff;
    max-width: 240px;
    text-align: center;

    padding: 24px;
}

@media (max-width: 1200px) {
    .content.vicinity-map .nearby-establishments .establishment ul {
        column-count: 2;
    }
}
@media (max-width: 992px) {
    .content.vicinity-map .text-section .text-cont {
        padding: 0 40px;
    }
}
@media (max-width: 768px) {
    .content.vicinity-map .nearby-establishments .establishment ul {
        column-count: 1;
        width: unset;
    }
    .content.vicinity-map .text-section .text-cont h2 {
        font-size: 36px;
    }
    .content.vicinity-map .text-section .text-cont h4 {
        font-size: 16px;
    }
}
@media (max-width: 767.98px) {
    
    .content.vicinity-map .map-section .map-location-control,
    .content.vicinity-map .map-section .nearby-redirect {
        display: none;
    }
    .content.vicinity-map .map .map-location-control-mobile {
        display: flex;
    }
    .content.vicinity-map .map {
        margin: auto;
        border-radius: 0;
        max-height: calc(100vh - 90px);
    }
    .content.vicinity-map .map-section {
        min-height: 0;
        padding: 0;
    }
    .content.vicinity-map .map .map-control button {
        height: 40px;
        width: 40px;
        font-size: 1em;
    }

    .content.vicinity-map .map .map-control .left-control {
        padding-right: 8px;
    }
    .content.vicinity-map .map .map-control .right-control {
        padding-left: 8px;
    }
    .content.vicinity-map .map .map-content .img-cont {
        min-width: 1920px;
        max-width: 1920px;
    }
    .content.vicinity-map .text-section .text-cont {
        padding: 0 48px;
    }
}
@media (max-width: 576px) {
    .content.vicinity-map .map-section .map-instructions {
        display: flex;
    }
    .content.vicinity-map .establishment-header h3 {
        font-size: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }
    .content.vicinity-map .map {
        max-height: calc(100vh - 72px);
    }
}

.content.vicinity-map .nearby-establishments .establishment.transpo ul {
    column-count: 1;
}

@media (max-width: 374.98px) {
    .content.vicinity-map .map .map-location-control-mobile button {
        height: 48px;
        width: 48px;
    }
}
