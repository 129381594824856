.content.privacy {
    position: relative;

    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/textured-bg-3.jpg');

    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    scroll-behavior: smooth;
    scroll-padding-top: 90px;    
}

.content.privacy > .bg-color {
    /* z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */

    /* background-color: rgba(255, 255, 255, .85); */

    box-sizing: border-box;
    padding: 40px 0;
}

.content.privacy #main {
    max-width: 1200px;
    margin: auto;
    
    box-sizing: border-box;
    padding: 40px;
    padding-top: 12px;
}

.content.privacy p {
    text-indent: 48px;

    font-weight: 300;
}
.content.privacy main {
    width: 100%;
    
    /* background-color: rgba(24, 53, 97, .85); */
}
.content.privacy main h2 {
    font-size: 48px;
    text-align: center;
    font-weight: 500;
    /* text-transform: uppercase; */

    font-family: 'Optima';
    color: #003262;

    margin-top: 12px;
    margin-bottom: 40px;
}
.content.privacy main h4 {
    font-weight: 400;
    font-size: 16px;

    margin-top: 24px;
}
.content.privacy main h6 {
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #2f2f2f;
}
.content.privacy main ul {
    margin-top: 0px;
}

.content.privacy main ul li {
    margin-bottom: 16px;
}
.content.privacy main ul li a {
    color: #003262;
    text-decoration: none;    
    font-weight: bold;
}

.content.privacy main ul li a:hover {
    border-bottom: solid 1px #003262;
}

.content.privacy main > div {
    margin-top: 48px;
    font-weight: 300;
}

.content.privacy .sect {
    width: 100%;

    padding: 48px 0!important;
}
.content.privacy .sect,
.content.privacy main {
    box-sizing: border-box;
    padding: 0 24px;
}

.content.privacy .sect h5 {
    font-size: 18px;
    margin: unset;

    margin-bottom: 12px;
    line-height: 1.4em;
}
.content.privacy .sect h5 a {
    margin-right: 8px;

    color: #4f4f4f;
}

.content.privacy main p,
.content.privacy .sect p {
    line-height: 1.5em;
}

.img-privacy {
    text-align: center;
}

@media (max-width: 1024px) {
    .content.privacy #main {
        padding: 40px 72px;
    }
}

@media (max-width: 578px) {

    .content.privacy #main {
        padding: 0px 42px;
    }
    .content.privacy .sect, .content.privacy main {
        padding: 0;
    }
    .content.privacy p {
        text-indent: 0;
    }
    .content.privacy main h2 {
        font-size: 36px;
    }
    .content.privacy main h4,
    .content.privacy .sect p,
    .content.privacy main p {
        text-align: justify;
        text-align-last: center;
    }
}

@media (max-width: 420px) {
    .content.privacy .sect, .content.privacy main {
        padding: 0;
    }
    .content.privacy #main {
        padding: 24px 32px;
    }
    .content.privacy main h4 {
        text-align: center;
    }
    .content.privacy main ul {
        padding-inline-start: 20px;
    }
}

@media (max-width: 360px) {
    .content.privacy > div {
        /* padding: 40px 12px; */
    }
    .content.privacy main h2 {
        font-size: 28px;
    }

    .content.privacy p {
        text-indent: 0;
        text-align: center;
    }
    .content.privacy .sect, .content.privacy main {
        /* padding: 0 20px; */
    }
    .content.privacy .sect h5 {
        text-align: left;
    }
    .content.privacy .sect p {
        margin-top: 16px;
    }
}