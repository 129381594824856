
  #sib-container input:-ms-input-placeholder {
    text-align: left;
    font-family: "Optima";
    color: #c0ccda;
  }

  #sib-container input::placeholder {
    text-align: left;
    font-family: "Optima";
    color: #c0ccda;
  }

  #sib-container textarea::placeholder {
    text-align: left;
    font-family: "Optima";
    color: #c0ccda;
  }