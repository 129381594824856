.contact-us {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    
    margin: auto;

    /* padding-top: 60px; */
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 120px;

    /* background-color: rgba(230, 230, 230, 1); */
}

.contact-us > h4 {
    font-size: 48px;
    font-weight: 500;
    font-family: "Optima";
    color: #003262;

    text-align: center;
    margin-bottom: 4px;
}
.contact-us > h6 {
    font-size: 16px;
    font-weight: 300;

    margin: 0;

    /* color: #2f2f2f; */
    text-align: center;
}
.contact-us > small {
    display: block;
    max-width: 900px;
    
    margin: 40px auto;

    color: #4f4f4f;

    text-align: center;

    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
}

.contact-us .contact-form {
    display: flex;
    align-items: flex-start;

    max-width: 1200px;
    margin: auto;
    margin-top: 48px;
    background-color: rgba(255, 255, 255, 1);

    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    max-height: 640px;
}
.contact-us .contact-form .contact-card {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    justify-content: space-between;

    box-sizing: border-box;
    border-radius: 16px;
    border-bottom-right-radius: 0;

    background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/contact-card-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 640px;
    width: 100%;
    max-width: 420px;
}

.contact-us .contact-form .contact-card p {
    z-index: 11;
    margin-left: 24px;
    font-size: 48px;

    color: #fff;
}
.contact-us .contact-form .contact-card p span {
    font-size: 90px;
    font-weight: bold;
    letter-spacing: .1em;
    font-family: "Natalie";
}

.contact-us .contact-form .contact-card > img {
    z-index: 11;
    margin-top: 24px;
    margin-left: 24px;

    max-width: 40px;
}
.contact-us .contact-form .contact-card .info {
    z-index: 11;

    color: #fff;
    margin-left: 24px;
    margin-bottom: 24px;
}
.contact-us .contact-form .contact-card .info h6 {
    display: flex;
    align-items: center;

    margin-bottom: 12px;

    font-size: 14px;
    font-weight: 400;
}
.contact-us .contact-form .contact-card .info h6:last-child {
    margin-bottom: unset;
}
.contact-us .contact-form .contact-card .info h6 > img {
    margin-right: 8px;
}

.contact-us .contact-form .contact-card .card-color {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(24, 53, 97, .85);
    border-radius: 16px;
    border-bottom-right-radius: 0;
}

.contact-us .contact-form .contact-text.form {
    width: 100%;
    align-self: stretch;

    display: flex;
    /* align-items: flex-start; */

    box-sizing: border-box;
    /* padding: 28px 40px; */

    overflow-y: auto;
    
}
.contact-us .contact-form .contact-text.form form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-content: space-between;
    
    box-sizing: border-box;
    padding: 32px 24px;
}

.contact-us .contact-form .contact-text.form form .error-message {
    display: flex;
    align-items: center;
    margin-top: 4px;

    font-size: 12px;
    font-weight: bold;
    color: #1f1f1f;
    background-color: #FFCCCC;

    opacity: 0;
    height: 2em;
    max-height: 0;

    box-sizing: border-box;
    padding: 0 4px;
    overflow: hidden;

    transition: all 400ms ease-in-out;
}

.contact-us .contact-form .contact-text.form form .input-group {
    display: flex;
    flex-direction: column;

    /* margin-bottom: 24px; */

    min-height: 100px;

    width: 100%;
    max-width: 320px;
    
    margin-right: 40px;
    
}
.contact-us .contact-form .contact-text.form form .input-group input[type="text"] {
    text-transform: capitalize;
}

.contact-us .contact-form .contact-text.form form .input-group input[type="number"]::-webkit-outer-spin-button,
.contact-us .contact-form .contact-text.form form .input-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.contact-us .contact-form .contact-text.form form .input-group input[type="number"] {
    -moz-appearance: textfield;
}

.contact-us .contact-form .contact-text.form form .input-group.message {
    max-width: unset;
}

.contact-us .contact-form .contact-text.form form .input-group.message textarea {
    max-width: 420px;
    width: 100%;
    

    background-color: transparent;
    padding: 8px;
    box-sizing: border-box;
    outline-color: #003262;
}

.contact-us .contact-form .contact-text.form form .input-group label {
    margin-bottom: 8px;
    /* font-size: 14px; */
    font-weight: bold;
    /* text-shadow: 1px 1px rgba(0, 0, 0, .15); */
    color: #003262;
}
.contact-us .contact-form .contact-text.form form .input-group input {
    box-sizing: border-box;
    padding: 6px;
    border: none;
    border-bottom: solid 1px #808080;

    font-size: 16px;
    /* letter-spacing: .025em; */

    width: 100%;
    background-color: transparent;

    outline: none;
    /* outline-color: #003262; */
}

.contact-us .contact-form .contact-text.form form .subject-choices {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 12px;
    margin-bottom: 24px;
}
.contact-us .contact-form .contact-text.form form .subject-choices h5 {
    width: 100%;
    margin: 0;
    margin-bottom: 12px;
}

.contact-us .contact-form .contact-text.form form h5 {
    font-size: 18px;
    margin: 12px unset;
    font-weight: normal;
}


.contact-us .contact-form .contact-text.form form .subject-choices .radio-group {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100px;
    margin: 0 24px;
    margin-bottom: 12px;
}

.contact-us .contact-form .contact-text.form form .subject-choices .radio-group:last-child {
    margin-right: unset;
}

.contact-us .contact-form .contact-text.form form .subject-choices .radio-group input[type="radio"] {
    accent-color: #003262;
    margin: 0;
}

.contact-us .contact-form .contact-text.form form .subject-choices .radio-group label {
    margin-left: 6px;
    font-size: 16px;
}

.contact-us .contact-form .contact-text.form form .submit-cont {
    width: 100%;
    display: flex;

    margin-top: 24px;
    padding-bottom: 24px;
}
.contact-us .contact-form .contact-text.form form .submit-cont .btn-submit {

    font-size: 16px;

    box-sizing: border-box;
    padding: 8px 12px;

    width: 100%;
    max-width: 200px;

    color: #003262;
    background-color: transparent;
    border: solid 1px #003262;

    font-weight: bold;
    border-radius: 500px;
    cursor: pointer;
}
.contact-us .contact-form .contact-text.form form .submit-cont .btn-submit:hover,
.contact-us .contact-form .contact-text.form form .submit-cont .btn-submit:focus {
    background-color: #003262;
    color: #fff;
}

.contact-us .contact-form .contact-text.form form .submit-cont .btn-submit:disabled {
    border: solid 1px #808080;
    color: #808080;

    cursor: default;
}

.contact-us .contact-form .contact-text.form form .submit-cont .btn-submit:disabled:hover {
    background-color: transparent;
    color: #808080;
}


.content.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 9500;


    display: flex;
    align-items: center;
    justify-content: center;

    max-height: 100vh;

    background-color: #fff;

    transition: opacity 400ms ease-in-out;
}


.content.loading-screen .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #10B981;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-screen > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-screen .brand-logo {
    width: 100%;
    max-width: 200px;
    margin-bottom: 32px;
}

.contact-us .contact-form .contact-text.form form .recaptcha {
    display: flex;
    width: 100%;
    justify-content: space-between;

    box-sizing: border-box;
    margin-top: 16px;
}

@media (max-width: 1280px) {
    .contact-us .contact-form .contact-text.form form .subject-choices {
        flex-wrap: wrap;
    }
}
@media (max-width: 992px) {
    .contact-us .contact-form .contact-text.form form .input-group {
        max-width: none;
        margin-right: unset;
    }
}

@media (max-width: 768px) {
    .contact-us .contact-form {
        border-radius: 16px;
    }
    .contact-us .contact-form .contact-card {
        display: none;
    }
    .contact-us .contact-form .contact-text.form {
        overflow: hidden;
    }
    .contact-us .contact-form .contact-text.form form .input-group:last-child {
        padding-bottom: 0;
    }
    .contact-us .contact-form .contact-text.form form .input-group {
        /* margin-bottom: 48px; */
    }
    .contact-us .contact-form {
        max-height: unset;
    }
    .contact-us .contact-form .contact-text.form form .submit-cont {
        justify-content: center;
        padding-bottom: 0;
    }
    .contact-us .contact-form .contact-text.form form .input-group.message textarea {
        max-width: none;
    }
}
@media (max-width: 576px) {
    .contact-us > h4 {
        font-size: 32px;
    }
    .contact-us {
        padding-left: 32px;
        padding-right: 32px;

        background-color: #fff;
        padding-top: 24px;
        border-radius: 0;
    }
    .contact-us .contact-form {
        background-color: transparent;
    }
    .contact-us .contact-form .contact-text.form {
        /* padding: 24px 20px; */
    }
    .contact-us .contact-form .contact-text.form form {
        padding: 0;
    }
    .contact-us .contact-form .contact-text.form form .submit-cont .btn-submit {
        margin: auto;
    }
}
@media (max-width: 375px) {
    .contact-us {
        padding-left: 24px;
        padding-right: 24px;
    }
}