.vicinity-map {
    position: relative;
    width: 100%;

    min-width: 100%;
    margin: auto;
}


/* Top Control */

.vicinity-map .top-controls {
    z-index: 200;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;

    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    /* width: 100%; */
}

.vicinity-map .top-controls button {
    background-color: #fff;

    font-size: 15px;
    font-weight: bold;
    /* letter-spacing: 1px; */
    color: #003262;

    min-width: 120px;
    margin: auto 12px;

    border: solid 4px #fff;
    border-radius: 600px;
    box-sizing: border-box;
    padding: 10px 24px;

    cursor: pointer;

    box-shadow:  0px 2px 6px rgba(0, 0, 0, .15);
    transition: transform 100ms ease-in-out;
}
/* .vicinity-map .top-controls button:hover, */
.vicinity-map .top-controls button.toggled {
    background-color: #003262;
    border-color: #ba9b6a;
    color: #fff;
}
.vicinity-map .top-controls button:active {
    /* transform: scale(.9); */
}
@media (max-width: 576px) {
    .vicinity-map .top-controls {
        box-sizing: border-box;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .vicinity-map .top-controls button:last-child {
        margin-bottom: unset;
    }
}
@media (max-width: 425px) {
    .vicinity-map .top-controls button {
        width: 100%;
    }
    .vicinity-map .top-controls button:first-child {
        margin-bottom: 12px;
    }
}




.vicinity-map .batulao-img {
    display: block;
    z-index: 100;
    width: 100%;

    min-height: 100%;
}


.vicinity-map .batulao-school,
.vicinity-map .batulao-church {
    z-index: 101;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    opacity: 0;
    transition: all 400ms ease-in-out;
    transform: translateY(12px);
}

.vicinity-map .batulao-school.show,
.vicinity-map .batulao-church.show {
    opacity: 1;
    transform: translateY(0px);
}


@media (max-width: 992px) {
    .vicinity-map {
        width: 90%;
    }
}
@media (max-width: 768px) {
    .vicinity-map {
        width: 96%;
    }
}
@media (max-width: 576px) {
    .vicinity-map {
        width: 100%;
    }
}




/* Nearby Establishments */
.additional-content {
    z-index: 201;
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    flex-wrap: wrap;


    height: 100%;
    min-height: 100%;
    max-height: 100%;
    min-width: 420px;   
    max-width: 480px;
    background-color: rgba(0, 50, 98, .95);

    transition: all 800ms ease-in-out;

    box-sizing: border-box;

    overflow-y: scroll;
}
.additional-content * {
    transition: opacity 600ms ease-in-out 600ms;
}
.additional-content.hide-content * {
    opacity: 0;
}

.additional-content .establishment {
    /* min-height: 100%;    */
    box-sizing: border-box;
    padding: 28px 20px;
    width: 100%;

    /* margin-bottom: 60px; */
    color: #fff;
    font-weight: bold;
    padding-bottom: 100px;
}
.additional-content .establishment.tourist {
    min-height: 100%;
}
.additional-content .establishment:nth-child(odd) {
    /* color: #fff;
    background-color: #ba9b6a; */
}
.additional-content .establishment:nth-child(even) {
    /* background-color: #003262; */
}
.additional-content .establishment .text-content {
}
.additional-content .establishment .text-content h4 {
    display: inline-block;
    padding: 8px 12px;
    margin-top: 0;
    margin-bottom: 4px !important;

    font-size: 20px;
    background-color: #003262;
    color: #fff;

    border: solid 6px #ba9b6a;
    border-radius: 500px;
    text-align: center;

    min-width: 240px;
}
.additional-content .establishment .text-content ul {
    list-style-type: none;
    padding-inline-start: 32px;
    padding-inline-end: 40px;
}

.additional-content .establishment .text-content ul li {
    margin-bottom: 20px;
}
.additional-content .establishment .text-content ul li p {
    font-size: 18px;
    margin: 0;
}

.additional-content .establishment .text-content ul li span {
    display: block;
    /* color: #2f2f2f; */
    font-size: 15px;
    font-weight: normal;

    line-height: 1.2em;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 12px;
}



.vicinity-map-mobile {
    width: 100%;
}
.vicinity-map-mobile .img-cont .batulao-img {
    width: 100%;
}
.vicinity-map-mobile .mobile-controls {
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;

    /* width: 100%; */
}

.vicinity-map-mobile .mobile-controls button {
    background-color: #fff;

    font-size: 15px;
    font-weight: bold;
    /* letter-spacing: 1px; */
    color: #003262;

    min-width: 148px;
    margin: auto 6px;
    margin-bottom: 16px;

    border: solid 4px #fff;
    border-radius: 600px;
    box-sizing: border-box;
    padding: 10px 24px;

    cursor: pointer;

    box-shadow:  0px 2px 6px rgba(0, 0, 0, .15);
    transition: transform 100ms ease-in-out;
}

.vicinity-map-mobile .batulao-img {
    display: block;
    z-index: 100;
    width: 100%;

    min-height: 100%;
}


.vicinity-map-mobile .batulao-school,
.vicinity-map-mobile .batulao-church {
    z-index: 101;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    opacity: 0;
    transition: all 400ms ease-in-out;
    transform: translateY(12px);
}

.vicinity-map-mobile .batulao-school.show,
.vicinity-map-mobile .batulao-church.show {
    opacity: 1;
    transform: translateY(0px);
}
