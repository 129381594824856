@font-face {
  font-family: 'Natalie';
  src: url(./fonts/Natalie.ttf);
}
@font-face {
  font-family: 'Optima';
  src: url(./fonts/OPTIMA.TTF);
}

* {
  font-family: 'Optima';
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {

  height: 100vh;
  max-height: calc(100vh - 90px);

  box-sizing: border-box;
  /* padding: 4px 24px; */
  
  overflow-y: auto;
}

.content .back-cont {
  z-index: 120;
  position: fixed;
  left: 20px;
  top: 112px;
  height: 0;
  width: 100%;
  max-width: 52px;

  transition: max-width 600ms ease-in-out;
}
.content .back-cont .back-btn {

  display: flex;
  align-items: center;
  width: 100%;

  color: #fff;
  background-color: #003262;
  border: 4px solid #ba9b6a;
  border-radius: 500px;
  box-sizing: border-box;
  /* box-shadow: 0px 2px 6px rgba(0, 0, 0, .25); */

  padding: 10px 12px;

  font-size: 16px;
  overflow-x: hidden;
  white-space: nowrap;

  /* text-transform: uppercase; */
  /* text-align: center; */
  text-decoration: none;

  cursor: pointer;
  transition: all 400ms ease-in-out;
}
.content .back-cont:hover,
.content .back-cont:focus {
  max-width: 124px;
}
.content .back-cont .back-btn i {
  margin-right: .56em;
  font-size: 1.25em;
}


@media (max-width: 576px) {
  .content {
    max-height: calc(100vh - 72px);
  }
  .content .back-cont {
    top: 90px;
    left: 12px;
  }
}

.redirect-btn {
  display: none;
  color: #fff;
  text-decoration: none;

  text-align: center;
  margin-top: 60px;

  animation: pulsing 2s ease-in-out infinite;

  -webkit-tap-highlight-color: transparent;
}
@keyframes pulsing {
  0% {
      opacity: 1;
  }
  50% {
      opacity: .4;
  }
  100% {
      opacity: 1;
  }
}

.content.page-error {
  display: flex;
  background: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/Aerialv2.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.content.page-error div.text-cont {
  display: flex;
  flex-direction: column;
  margin: auto;
  transform: translateY(-100px);
}
.content.page-error h1 {
  margin: 0 auto; 
  margin-top: 12px;
  font-size: 24px;
  text-align: center;
}
.content.page-error h2 {
  font-size: 24px;
}
.content.page-error div.text-cont .icon-404 {
  margin: 0 auto;
  margin-bottom: 12px;
  width: 100%;
  max-width: 360px;
}
.content.page-error div.text-cont > p {
  margin: 8px auto;
  text-align: center;
}

.content.page-error div.text-cont > a {
  display: inline-block;
  margin: 0 auto;
  margin-top: 12px;

  color: black;
  border: solid 2px #ba9b6a;
  border-radius: 42px;

  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  min-width: 160px;
}
.content.page-error div.text-cont > a:hover,
.content.page-error div.text-cont > a:focus {
  color: #fff;
  background-color: #ba9b6a;
}

@media (max-width: 576px) {
  .content.page-error div.text-cont .icon-404 {
    max-width: 75%;
  }
}