nav {
    position: relative;
    z-index: 10000;
    
    background-color: rgba(255, 255, 255, 1);

    height: 100vw;
    max-height: 90px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav img.logo {
    position: absolute;
    top: 12px;
    left: 12px;
    max-height: 72px;
    
    /* margin-left: 24px; */
    background-color: #fff;
    border-radius: 12px;
    padding: 0 12px;
}

nav .navbar-toggler-desktop {
    display: none;
    
    box-sizing: border-box;
    border-radius: 2px;

    padding: 0px 6px ;

    font-size: 24px;

    cursor: pointer;
}

nav button:active {
    background-color: transparent;
    color: #003262;
}

nav ul {
    list-style-type: none;

    display: flex;
    margin-right: 40px;
}

nav li {
    /* margin: 0 16px; */
}

nav li a {
    color: #1f1f1f;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 24px;
}

nav li a.active {
    border: solid 4px #ba9b6a;

    color: #fff;
    background-color: #ba9b6a;
}

@media (min-width: 992px) {
    nav li a {
        font-size: 16px;
    }
    nav li a.active {
        border-radius: 100px;
    }
}

nav > div.dropdown .mobile {
    display: flex;
    position: fixed;

    top: 90px;
    right: 0;
    z-index: 9000;

    
    background-color: #003262;
    
    transform: translateX(100%);
    transition: all 500ms ease;
}
nav > div.dropdown .mobile.show {
    transform: translateX(0);
}

nav > div.dropdown .mobile ul {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin: unset;
    padding: unset;

    padding: 18px;
    padding-left: 42px;
    padding-right: 0;
}
nav > div.dropdown .mobile ul li {
    margin: unset;
    margin-bottom: 12px;
}

nav > div.dropdown .mobile div.lattice {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .1;

    background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/lattice.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
nav > div.dropdown .mobile div.color-overlay {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(70deg, rgba(0, 50, 98, 5) 30%, rgba(0, 0, 0, 0));
}
 
@media (max-width: 992px) {
    nav > div.dropdown {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    nav .navbar-toggler-desktop {
        display: block;
        align-self: flex-end;
        margin-right: 24px;
    }

    nav li a {
        display: inline-block;
        width: 160px;
        text-align: right;
    }
    nav ul.desktop {
        display: none;
    }
    
    nav * {
        color: #fff !important;
    }
}


@media (max-width: 576px) {
    nav .navbar-toggler-desktop {
        margin-right: 12px;
    }
    nav > div.dropdown .mobile {
        display: flex;
        position: fixed;
        top: 0;
        left: unset;
        right: 0;

        width: 70vw;

        overflow: hidden;
        border-radius: unset;
        
        height: 100vh;

        transform: translateX(100%);
        transition: all 500ms ease;
    }

    nav > div.dropdown .mobile.show {
        transform: translateX(0);
    }
    nav > div.dropdown .mobile img.navbar-toggler-mobile {
        z-index: 1000;
        position: absolute;
        top: 20px;
        right: 16px;
    }

    nav > div.dropdown .mobile ul {
        margin-left: auto;
        margin-bottom: auto;
        margin-top: 90px;
        margin-right: 40px;
        align-items: center;

        padding: 24px 0;
    }
    nav > div.dropdown .mobile ul li a {
        display: inline-block;
        width: 120px;
        text-align: right;

        font-size: 18px;
    }
    nav li a.active {
        display: block;

        min-width: 120px;
    }
    nav {
        max-height: 72px;
    }
    nav img.logo {
        position: static;
        max-height: 60px;
        margin-left: 12px;
    }
}
@media (max-width: 375.98px) {
    nav > div.dropdown .mobile {
        width: 75vw;
    }
}
@media (max-width: 320.98px) {
    nav > div.dropdown .mobile {
        width: 85vw;
    }
}
@media (min-width: 576px) {
    nav > div.dropdown .mobile img.navbar-toggler-mobile {
        display: none;
    }
}
@media (min-width: 992px) {
    nav > div.dropdown .mobile {
        display: none !important;
    }
}
