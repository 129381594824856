.content.explore {
    scroll-behavior: smooth;
    scroll-padding-top: 0px;
    overflow-x: hidden;
    
}
.content.explore .main {
    position: relative;
    display: flex;

    width: 100%;
    height: 600px;
    background-image: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/Explore-main-bg-3.jpg');
    background-size: cover;
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.content.explore .main .color-bg {
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;
    width: 100%;

    background-color: rgba(0, 50, 98, .7);
}


.content.explore .main .text-cont { 
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 102;
    
    color: #fff;
    margin: auto;
    max-width: 600px;
}

.content.explore .main .text-cont .redirect-cont {
    display: inline-flex;
    margin: auto;
    margin-top: 40px;
}
.content.explore .main .text-cont .redirect-cont a {
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    text-decoration: none;

    color: #fff;
    background-color: #ba9b6a;

    border: solid 4px #ba9b6a;
    border-radius: 200px;
    padding: 10px 20px;
}
.content.explore .main .text-cont .redirect-cont a:hover {
    background-color: #003262;
}

.content.explore .main .text-cont .redirect-cont a:first-child {
    margin-right: 16px;
}

@media (max-width: 576px) {
    .content.explore .main .text-cont .redirect-cont {
        flex-direction: column;
    }
    .content.explore .main .text-cont .redirect-cont a:first-child {
        margin-right: 0;
        margin-bottom: 12px;
    }
}

.content.explore .main .text-cont h2 {
    margin: unset;
    text-align: center;
    font-size: 48px;

    text-transform: uppercase;
    letter-spacing: .05em;
    margin-bottom: 16px;
}

.content.explore .main .text-cont p {
    font-size: 20px;
    text-align: center;

    margin: unset;
    padding: 0 32px;
}
.content.explore .main .text-cont p i {
    font-family: 'natalie';
    font-size: 40px;
    line-height: 1.35em;
}
.content.explore > .title-cont {
    display: flex;
    width: 100%;

    padding-top: 42px;
    padding-bottom: 42px;
}
.content.explore .title-cont .guide-title {
    display: inline-block;
    margin: unset;

    color: #fff;
    background-color: #003262;

    box-sizing: border-box;
    border: solid 5px #ba9b6a;
    border-radius: 12px;

    padding: 6px 42px;


    font-size: 36px;
    text-align: center;

    margin: auto;
}

.content.explore .guide-cont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    padding: 0px 20px;
    padding-bottom: 60px;
    max-width: 1600px;

    margin: auto;
}
.content.explore .guide-cont::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    background: url('https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/explore-page/textured-bg-3.jpg') no-repeat center;
    background-size: cover;
    will-change: transform;
    z-index: -1;
}
.content.explore .guide-cont .disclaimer-cont {
    display: flex;
    width: 100%;
}
.content.explore .guide-cont small.disclaimer {
    margin: 0 auto;
    text-align: center;
    max-width: 720px;
}

.content.explore .guide-cont .steps {
    visibility: hidden;
    opacity: 0;
    display: flex;
    width: 100%;
    max-width: 480px;
    /* margin: 0 auto; */
    min-height: 320px;
    /* background-color: #003262; */
    color: #003262;
    box-sizing: border-box;
    padding: 40px;

    transition: all 400ms ease-in;
}
.content.explore .guide-cont .steps:nth-child(1) {
    visibility: visible;
}

.content.explore .guide-cont .steps:nth-child(odd) {
    transform: translateX(-40px);
}
.content.explore .guide-cont .steps:nth-child(odd).show {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
}
.content.explore .guide-cont .steps:nth-child(even) {
    transform: translateX(40px);
}
.content.explore .guide-cont .steps:nth-child(even).show {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
}

.content.explore .guide-cont .steps:nth-child(5) {
    /* max-width: none; */
}

.content.explore .guide-cont .steps .step-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    max-width: 720px;
}

.content.explore .guide-cont .steps .step-content .step-header {
    height: 72px;
    margin-bottom: 32px;
}
.content.explore .guide-cont .steps .step-content .title-img {
    height: 20px;
}
.content.explore .guide-cont .steps .step-content .step-lists {
    position: relative;
    width: 100%;
    /* margin-left: 48px; */
    margin-top: 24px;
}

.content.explore .guide-cont .steps .step-content .step-lists .cont-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 48px;
}
.content.explore .guide-cont .steps .step-content .step-lists .cont-list .box-icon {
    height: 60px;
}
.content.explore .guide-cont .steps .step-content .step-lists .cont-list ul {
    margin: unset;
    margin-top: 4px;
}
.content.explore .guide-cont .steps .step-content .step-lists .cont-list ul li {
    max-width: 300px;
}
.content.explore .guide-cont .steps .step-content .step-lists .cont-list ul li {
    margin-bottom: 4px;
}
.content.explore .guide-cont .steps .step-content .step-lists .cont-list ul li i {
    font-size: 14px;
}



.content.explore .guide-cont .steps:nth-child(even){
    justify-content: flex-end;
}

.content.explore .guide-cont .steps:nth-child(even) .step-content .step-header {
    /* align-self: flex-end; */
}

.content.explore .guide-cont .steps.step1 .step-content {
    /* margin-left: 8%; */
    margin-right: auto;
}
.content.explore .guide-cont .steps.step3 .step-content {
    max-width: 600px;
}
.content.explore .guide-cont .steps.step5 .step-content {
    /* max-width: 600px;
    margin: auto; */
    /* margin-right: auto;
    margin-left: 12%; */
}


.icon-others {
    display: none;
    margin-left: auto;
    position: absolute;
    top: 0;
    right: 20px;

    /* transform: translateX(110%); */
    max-height: 280px;
}
.content.explore .guide-cont .steps .step-content .step-lists .transfer-icon {
    max-height: 240px;
}
.content.explore .guide-cont .steps .step-content .step-lists .percent-icon {
    position: static;
    
    margin-top: -20px;
    margin-left: 20px;
    max-height: 120px;
}
.content.explore .guide-cont .steps .step-content .step-lists .file-check {
    margin-top: -16px;
    max-height: 100px;
}
.content.explore .guide-cont .steps .step-content .step-lists .percent-icon2 {
    top: -20px;
    max-height: 120px;
}

.content.explore .guide-cont .steps .step-content .step-lists .house-hammer {
    margin-top: -8px;
    margin-right: 28px;
    position: static;
    /* right: 8px;
    top: 0; */
    max-height: 90px;
}

.content.explore .guide-cont .steps .step-content .step-lists .house-percent {
    top: -40px;
    max-height: 100px;
}

.content.explore .guide-cont .steps .step-content .step-lists .house-check {
    /* top: -24px;
    right: 32px; */
    margin-top: -16px;
    max-height: 100px;
}



.content.explore .guide-cont .steps .step-content .step-lists .img-cont {
    position: static;
    /* top: -12px;
    right: 80px; */
}

.content.explore .guide-cont .steps .step-content .step-lists .img-cont .house-peso {
    max-height: 100px;
    margin-right: 32px;
}
.content.explore .guide-cont .steps .step-content .step-lists .img-cont .percent-icon-100 {
    max-height: 100px;
}
.content.explore .guide-cont .steps.step4 .step-content .step-lists .cont-list:first-child  {
    margin-bottom: 28px;
}





@media (max-width: 1023.98px) {
    .content.explore .guide-cont .steps .step-content .step-header {
        height: 60px;
    }
    .content.explore .guide-cont .steps .step-content .step-lists .cont-list .box-icon {
        height: 54px;
    }
    .content.explore .guide-cont .steps .step-content {
        margin: auto !important;
    }
    
    .content.explore .guide-cont .steps.step1 .step-content .step-lists .cont-list ul {
        max-width: 320px;
    }


    .content.explore .guide-cont .steps .step-content .step-lists .transfer-icon {
        max-height: 240px;
        right: 0;
    }

    .content.explore .guide-cont .steps .step-content .step-lists .percent-icon {
        max-height: 100px;
    }
    .content.explore .guide-cont .steps .step-content .step-lists .house-hammer {
        max-height: 90px;
    }
    .content.explore .guide-cont .steps .step-content .step-lists .percent-icon2 {
        max-height: 100px;
    }
    .content.explore .guide-cont .steps .step-content .step-lists .house-percent {
        max-height: 80px;
    }
    .content.explore .guide-cont .steps .step-content .step-lists .img-cont .percent-icon-100 {
        max-height: 90px;
    }
    .content.explore .guide-cont .steps .step-content .step-lists .img-cont .house-peso {
        max-height: 90px;
    }
    .content.explore .guide-cont .steps .step-content .step-lists .house-check {
        max-height: 80px;
    }
    .content.explore .guide-cont {
        padding: 32px 20px;
    }
    .content.explore > .title-cont {
        margin-bottom: 0px;
    }
}
@media (max-width: 767.98px) {
    .content.explore .guide-cont .steps .step-content {
        max-width: 420px !important;
    }

    .content.explore .guide-cont .steps .step-content .step-lists .icon-others {
        display: none;
    }
}
@media (max-width: 575.98px) {
    .content.explore .guide-cont .steps .step-content .title-img.buyer,
    .content.explore .guide-cont .steps .step-content .title-img.phirst {
        display: none;
    }
    .content.explore .main .text-cont h2 {
        font-size: 42px;
    }
    .content.explore .main .text-cont p {
        font-size: 18px;
    }
    .content.explore .main {
        height: 100vh;
        max-height: calc(100vh - 72px);
    }
    .redirect-btn {
        display: block;
    }
    .content.explore .title-cont .guide-title {
        font-size: 28px;
    }
}
@media (min-width: 575.98px) {
    .content.explore .guide-cont .steps .step-content .title-img.buyer-alt,
    .content.explore .guide-cont .steps .step-content .title-img.phirst-alt {
        display: none;
    }
}
@media (max-width: 479.98px) {
    .content.explore .guide-cont .steps {
        padding: 32px 12px;
    }
}
@media (max-width: 424.98px) {
    .content.explore .guide-cont .steps .step-content .step-lists .cont-list .box-icon {
        height: 32px;
    }
    .content.explore .guide-cont .steps .step-content .step-lists .cont-list ul {
        margin-top: unset;
        padding-inline-start: 28px;
    }
    .content.explore .guide-cont .steps .step-content .step-header {
        height: 52px;
        margin-bottom: 16px;
    }
    .content.explore .main .text-cont h2 {
        font-size: 40px;
    }
}
@media (max-width: 374.98px) {
    .content.explore .main .text-cont h2 {
        font-size: 36px;
    }
    .content.explore .guide-cont .steps .step-content .step-header {
        height: 46px;
    }
    .content.explore .title-cont .guide-title {
        font-size: 24px;
        padding: 6px 32px;
    }
}


.content.explore .news-cont {
    padding-bottom: 120px;
    background-color: #fff;
}
.content.explore .news-cont .title-cont {
    display: flex;
    width: 100%;
    background-color: #ba9b6a;
    height: 100px;

    margin-top: 42px;
    margin-bottom: 42px;
}
.content.explore .news-cont h2.news-title {
    color: #fff;
    margin: auto;

    font-size: 32px;
}

.content.explore .news-cont .news {
    max-width: 1000px;
    margin: auto;
    margin-top: 120px;
}
.content.explore .news-cont .news small a {
    color: #003262;
}
.content.explore .news-cont .news h4 {
    font-size: 24px;
    text-align: center;
    margin: auto;
    margin-bottom: 24px;

    max-width: 800px;
}
.content.explore .news-cont .news img {
    width: 100%;
    margin-top: 12px;
}
.content.explore .news-cont .news p {
    font-size: 16px;
    margin-bottom: 28px;
}
.content.explore .news-cont .news h6 {
    font-size: 15px;
    text-align: right;
}
.content.explore .news-cont .news h6 a {
    color: black;
    text-decoration: none;
    border-bottom: solid 1px black;
}
.content.explore .news-cont .news h6 a:hover {
    color: #003262;
    border-bottom: solid 1px #003262;;
}

.content.explore .book-tripping {
    display: flex;
    width: 100%;
}

.content.explore .book-tripping a {
    margin: auto;
    padding: 10px 24px;

    color: #003262;
    border: solid 2px #003262;

    font-size: 16px;
    font-weight: bold;

    text-decoration: none;

    border-radius: 500px;
}
.content.explore .book-tripping a:hover {
    color: #fff;
    background-color: #003262;
    
}

@media (max-width: 1024px) {
    .content.explore .news-cont .news {
        padding-left: 60px;
        padding-right: 60px;
    }
}
@media (max-width: 425px) {
    .content.explore .news-cont .news {
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media (max-width: 374.98px) {
    .content.explore .news-cont .news {
        padding-left: 32px;
        padding-right: 32px;
    }
}


.content .book-tripping-modal {
    z-index: 500;
    position: fixed;
    right: 32px;
    bottom: 20px;

    border: solid 0px #ba9b6a;
    background-color: #fff;


    width: 100%;
    max-width: 420px;

    height: 100vh;
    max-height: 0;
    overflow: hidden;
    box-sizing: border-box;

    transform: translateY(0);

    transition: transform 600ms ease-in-out, max-height 600ms ease-in-out, border-width 600ms ease-in-out;
}
.content .book-tripping-modal.show {
    border-width: 6px;
    max-height: 600px;
    transform: translateY(-24px);
}
.content .book-tripping-modal * {
    opacity: 0;
    transition: opacity 400ms ease-in-out 600ms;
}
.content .book-tripping-modal.show * {
    opacity: 1;
}

.content .book-tripping-modal .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-right: 4px;
}
.content .book-tripping-modal .modal-header h4 {
    font-size: 20px;
    margin: 0;

    color: #fff;
    background-color: #003262;
    
    padding: 12px;
    /* border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; */

    min-width: 140px;
}
.content .book-tripping-modal .modal-header i {
    cursor: pointer;

    color: #003262;
    border: solid 2px #003262;
    background-color: transparent;

    padding: 4px;
    padding-bottom: 0px;

    border-radius: 6px;

    font-size: 1.25em;

    transition: border-radius 400ms ease-in-out;
}
.content .book-tripping-modal .modal-header i:hover {
    color: #fff;
    background-color: #003262;

    border-radius: 28px;
}


.content .book-tripping-modal .modal-content {
    margin: 0 6px;
    padding: 0 12px;
    box-sizing: border-box;

    max-height: calc(100% - 80px);
    padding-top: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;

    overflow-y: auto;
    scroll-behavior: smooth;
}
.content .book-tripping-modal .modal-content::-webkit-scrollbar {
  width: 4px;
}
.content .book-tripping-modal .modal-content form {
    width: 100%;
}
.content .book-tripping-modal .modal-content form .input-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    width: 100%;

    padding: 0 8px;
    box-sizing: border-box;
}
.content .book-tripping-modal .modal-content form .input-item label {
    font-size: 14px;
    margin-bottom: 6px;
}
.content .book-tripping-modal .modal-content form .input-item input,
.content .book-tripping-modal .modal-content form .input-item select {
    margin: 0;
    padding: 6px 8px;

    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    
    height: 36px;
}

.content .book-tripping-modal .modal-content form .input-item textarea {
    resize: none;
}

.content .book-tripping-modal .modal-content form .input-group {
    display: flex;
    width: 100%;

}


.content .book-tripping-modal .modal-content form .input-item button {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;

    border: solid 2px #003262;
    border-radius: 100px;
    color: #003262;

    background-color: transparent;

    padding: 8px;
    box-sizing: border-box;
    
    cursor: pointer;
}
.content .book-tripping-modal .modal-content form .input-item button:hover {
    color: #fff;
    background-color: #003262;
}
.content .book-tripping-modal .modal-content form .input-item button:disabled {
    border-color: gray;
    color: gray;
    background-color: transparent;
    cursor: default;
}

.content .book-tripping-modal .recaptcha {
    padding: 0 12px;
    box-sizing: border-box;
    
    margin-bottom: 12px;
}
.content .book-tripping-modal .recaptcha > div > div > div  {
    margin: auto;
}
.content .book-tripping-modal .recaptcha > div > div > div > div { 
    display: flex;
}


@media (max-width: 576px) {
    .content .book-tripping-modal {
        left: 0;
        right: 0;
        bottom: 0;

        margin: auto;
    }
    .content .book-tripping-modal.show {
        max-height: 720px;
    }
}
@media (max-width: 425px) {
    .content .book-tripping-modal {
        bottom: 0;
        transform: translateY(24px);
    }
    .content .book-tripping-modal.show {
        transform: translateY(0);
        max-height: calc(100vh - 72px);
    }
}
@media (max-width: 374.98px) {
    .content .book-tripping-modal .modal-content form .input-group {
        flex-direction: column;
    }
    .content .book-tripping-modal .recaptcha {
        padding: 0;
        margin-left: -16px;
        transform: scale(.8);
    }
}