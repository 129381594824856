.footer {
    position: relative;
    min-height: 400px;
    width: 100%;

    padding-top: 40px;
    padding-bottom: 40px;

    background-image: url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/70+EXPANDED.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.footer .bg-color {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 10;
    background-color: rgba( 25, 25, 25, .75);
}