.content.sales-pav {
    display: flex;
    flex-wrap: wrap;
}
/* .content.sales-pav::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    background: url('https://phirst-park.s3.ap-northeast-1.amazonaws.com/phirst-edition/Images/explore-page/images/texture-white-bg.jpg') no-repeat center;
    background-size: cover;
    will-change: transform;
    z-index: -1;
} */

.content.sales-pav > .sales-pav-main {
    position: relative;
    display: flex;
    margin: 0 auto;

    width: 100%;
    max-width: 1920px;
    height: calc(100vh - 90px);
    max-height: 1000px;
    overflow: hidden;

    background-color: #fff;
}


.content.sales-pav > .sales-pav-main .bg-image {
    position: absolute;
    display: block;
    width: auto;
    height: auto;
    top: -200px;
}

@media (max-width: 1920px) and (min-width: 1440px) {
    .content.sales-pav > .sales-pav-main {
        /* background-image: url("https://phirst-park.s3.ap-northeast-1.amazonaws.com/phirst-edition/Images/sales-pav/sales-pav-main-bg-alt.jpg"); */
    }
    .content.sales-pav > .sales-pav-main .bg-image {
        max-width: 1920px;
    }
}
@media (max-width: 1440px) and (min-width: 992px) {
    .content.sales-pav > .sales-pav-main .bg-image {
        max-width: 2400px;
        left: -40%;
        top: -360px;
    }
}
@media (max-width: 992px) and (min-width: 576px) {
    .content.sales-pav > .sales-pav-main .bg-image {
        max-width: 2400px;
        left: -40%;
        top: -360px;
    }
}
@media (max-width: 576px) and (min-width: 320px) {
    .content.sales-pav > .sales-pav-main .bg-image {
        max-width: 1600px;
        left: -40%;
        top: unset;
        bottom: -200px;
    }
}

.content.sales-pav > .sales-pav-main .main-img {
    margin: auto;
    width: 100%;
}

.content.sales-pav > .sales-pav-main .logo-cont {
    z-index: 200;
    position: absolute;
    max-width: 320px;
    top: 90px;
    left: 8%;
}
.content.sales-pav > .sales-pav-main .logo-cont img {
    width: 100%;
}


.content.sales-pav > .sales-pav-main .family-cont {
    z-index: 201;
    position: absolute;
    max-width: 720px;

    left: 12%;
    bottom: -40px;
}
.content.sales-pav > .sales-pav-main .family-cont img {
    width: 100%;
}

.content.sales-pav > .sales-pav-main .hand-rails {
    z-index: 200;
    position: absolute;
    bottom: -90px;
    left: 0;
    width: 100%;
    overflow: hidden;
}
.content.sales-pav > .sales-pav-main .hand-rails img {
    max-width: 1920px;
}



.content.sales-pav > .text-section {
    position: relative;
    display: flex;
    width: 100%;

    /* color: #fff; */
    min-height: 360px;
    background-color: #ba9b6a;
}
/* .content.sales-pav > .text-section::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    background: url('https://phirst-park.s3.ap-northeast-1.amazonaws.com/phirst-edition/Images/aboutUs-page/images/Sales-pav-front.jpg') no-repeat center;
    background-size: cover;
    will-change: transform;
    z-index: -1;
} */
.content.sales-pav > .text-section > .bg-color {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, .85); */
    /* background-color: rgba(0, 50, 98, .85); */
    /* background-image: linear-gradient(90deg ,rgba(0, 22, 42, .85) 10%, rgba(0, 62, 120, 0.85), rgba(0, 22, 42, .85) 90%); */
    /* background-color: rgba(186, 155, 106, .9); */
}


.content.sales-pav > .text-section .text-cont {
    z-index: 101;
    margin: auto;
    max-width: 900px;

    text-align: center;
}
.content.sales-pav > .text-section .text-cont h2 {
    font-size: 42px;
    margin: auto;
    margin-bottom: 12px;
    color: #fff;
}
.content.sales-pav > .text-section .text-cont p {
    font-size: 18px;
    color: #fff;
}

.content.sales-pav > .sales-pav-carousel {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    position: relative;
    margin: auto;
    width: 100%;
    max-width: 1920px;

    height: 100vh;
    /* max-height: 420px; */
    max-height: 800px;

    box-sizing: border-box;
    user-select: none;

    background-color: black;
}
.content.sales-pav > .sales-pav-carousel .carousel-control {
    z-index: 101;
    display: flex;
    position: absolute;
    bottom: 40px;
    right: 40px;
}

.content.sales-pav > .sales-pav-carousel .carousel-control i {
    display: flex;

    border: none;
    border-radius: 500px;

    color: #fff;
    background-color: #ba9b6a;
    cursor: pointer;
 
    margin: auto;
    font-weight: bold;
    font-size: 4em;
}

.content.sales-pav > .sales-pav-carousel .carousel-control i:active {
    background-color: #003262;
}

.content.sales-pav > .sales-pav-carousel .carousel-control i:first-child {
    margin-right: 12px;
}

.content.sales-pav > .sales-pav-carousel .text-content {
    z-index: 101;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    min-width: 480px;
    width: 42%;

    color: #fff;
    background-color: rgba(0, 28, 55, 0.9);

    padding: 40px 60px;
    box-sizing: border-box;
    /* background-image: linear-gradient(90deg, rgba(0, 28, 55, 0.9), rgba(0, 28, 55, 0.75), rgba(0, 0, 0, 0)); */
}
.content.sales-pav > .sales-pav-carousel .text-content > div {
    display: block;
    margin: auto;
    /* margin-left: 100px; */

    max-width: 600px;
}
.content.sales-pav > .sales-pav-carousel .text-content > div h2 {
    font-size: 36px;
    font-style: italic;
    margin: unset;
}
.content.sales-pav > .sales-pav-carousel .text-content > div p {
    font-size: 18px;
    line-height: 1.25em;
}


.content.sales-pav > .sales-pav-carousel > .scrolling-carousel {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;

    scroll-behavior: smooth;
}
.content.sales-pav > .sales-pav-carousel .carousel-item {
    z-index: 100;
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    max-width: 1920px;
    overflow: hidden;

    transition: all 1000ms ease-in-out;
}

.content.sales-pav > .sales-pav-carousel .carousel-item img {
    /* position: absolute; */
    min-width: 100%;
    position: absolute;
    right: 0;
    bottom: -25%;
}   

.content.sales-pav > .sales-pav-carousel .carousel-control-right,
.content.sales-pav > .sales-pav-carousel .carousel-control-left {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
    height: 100%;

    cursor: pointer;
}
.content.sales-pav > .sales-pav-carousel .carousel-control-left {
    left: 0;
}

.content.sales-pav > .sales-pav-carousel .carousel-control-right .control-bg,
.content.sales-pav > .sales-pav-carousel .carousel-control-left .control-bg {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 400ms ease-in-out;
    background-image: linear-gradient(to left, rgba(0, 50, 98, .75), rgba(0, 0, 0, 0));
}
.content.sales-pav > .sales-pav-carousel .carousel-control-left .control-bg {
    background-image: linear-gradient(to right, rgba(0, 50, 98, .75), rgba(0, 0, 0, 0));
}

.content.sales-pav > .sales-pav-carousel .carousel-control-right:hover .control-bg,
.content.sales-pav > .sales-pav-carousel .carousel-control-left:hover .control-bg {
    opacity: 1;
}

.content.sales-pav > .sales-pav-carousel .carousel-control-right:hover i,
.content.sales-pav > .sales-pav-carousel .carousel-control-left:hover i {
    color: #fff;
}

.content.sales-pav > .sales-pav-carousel .carousel-control-right i,
.content.sales-pav > .sales-pav-carousel .carousel-control-left i {
    z-index: 101;
    margin: auto;
    margin-left: auto;
    margin-right: 12px;
    font-size: 4rem;


    color: #fff;
    transition: all 1000ms ease-in-out;
}
.content.sales-pav > .sales-pav-carousel .carousel-control-left i {
    margin-left: 12px;
    margin-right: auto;
}




.content.sales-pav > .info-section {
    position: relative;
    display: flex;
    
    height: calc(100vh - 90px);
    max-height: 1000px;

    width: 100%;
    max-width: 1920px;

    margin: auto;

    box-sizing: border-box;
    padding: 2px;
    overflow: hidden;
    background-color: #003262;
    /* background-color: #ba9b6a; */
    /* background-image: url('https://phirst-park.s3.ap-northeast-1.amazonaws.com/phirst-edition/Images/explore-page/images/texture-white-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
}
.content.sales-pav > .info-section > .info-img {
    display: flex;
    align-items: flex-start;
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    /* background: linear-gradient(to right, #003262, #ba9b6a); */
    /* border-top-right-radius: 1400px;
    border-top: solid 8px #ba9b6a;
    border-right: solid 24px #ba9b6a; */
    overflow: hidden;
}
.content.sales-pav > .info-section > .info-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content.sales-pav > .info-section > .info-card {
    z-index: 101;
    margin: auto;
    margin-left: auto;
    margin-right: 160px;
    margin-top: 160px;

    width: 100%;
    max-width: 420px;


    border-radius: 16px;
    box-sizing: border-box;

    background-color: rgba(0, 50, 98, .85);
    overflow: hidden;

    opacity: 0;
    transition: all 600ms ease-in-out;
}
.content.sales-pav > .info-section > .info-card.show {
    opacity: 1;
    transform: translateX(-40px);
}
.content.sales-pav > .info-section > .info-card > h2 {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;

    color: #fff;
    /* background-color: #ba9b6a; */
    margin: unset;

    margin-top: 32px;
}
.content.sales-pav > .info-section > .info-card > .btn-cont {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    padding: 32px 16px;
}
.content.sales-pav > .info-section > .info-card > .btn-cont a {
    display: block;
    align-self: center;
    width: 100%;
    max-width: 320px;

    border: solid 4px #ba9b6a;
    
    background-color: transparent;
    background-color: #ba9b6a;
    
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    padding: 12px 24px;
    box-sizing: border-box;
    border-radius: 500px;

    text-decoration: none;
    margin-bottom: 16px;
}
.content.sales-pav > .info-section > .info-card > .btn-cont a:last-child {
    margin-bottom: 0;
}


.content.sales-pav > .info-section > .info-card > .btn-cont a:hover {
    background-color: #003262;
    /* outline: solid 4px #ba9b6a; */
}

.content.sales-pav > .sales-pav-main .anim-cont {
    display: flex;
    flex-direction: column;

    align-items: center;
    z-index: 200;
    position: absolute;
    top: 60px;
    right: 120px;

    width: 100%;
    max-width: 600px;
}
.content.sales-pav > .sales-pav-main .anim-cont .anim-item {
    display: inline-block;
    width: 100%;
    height: 64px;

    cursor: pointer;
}
.content.sales-pav > .sales-pav-main .anim-cont img {
    display: inline-block;
    height: 100%;
}
@media (max-width: 1440px) {
    .content.sales-pav > .info-section > .info-card {
        margin-right: 60px;
    }
}
@media (max-height: 1200px) {
    .content.sales-pav > .sales-pav-main .family-cont {
        max-width: 600px;
    }
}
@media (max-height: 900px) {
    .content.sales-pav > .sales-pav-main .family-cont {
        max-width: 540px;
    }
}
@media (max-width: 1200px) {
    .content.sales-pav > .sales-pav-main .anim-cont {
        right: 40px;
    }
    .content.sales-pav > .sales-pav-main .anim-cont .anim-item {
        height: 54px;       
    }
    .content.sales-pav > .sales-pav-main .logo-cont {
        max-width: 240px;
    }
}
@media (max-width: 1024px) {
    .content.sales-pav > .sales-pav-carousel {
        max-height: 720px;
    }
    .content.sales-pav > .sales-pav-carousel .text-content > div {
        /* margin-left: 60px; */
    }
    .content.sales-pav > .info-section > .info-card {
        margin: auto;
    }
    .content.sales-pav > .info-section > .info-card.show {
        opacity: 1;
        transform: translateX(0px) translateY(-40px);
    }
    .content.sales-pav > .sales-pav-main .logo-cont {
        max-width: 160px;
        top: 120px;
        left: 60px;
    }
    .content.sales-pav > .sales-pav-main .anim-cont {
        top: 90px;
        right: 40px;
        max-width: 500px;
    }
    .content.sales-pav > .sales-pav-main .anim-cont .anim-item {
        height: 48px;       
    }
}
@media (max-width: 768px) {
    .content.sales-pav > .sales-pav-main .logo-cont {
        max-width: 120px;
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .content.sales-pav > .sales-pav-main .anim-cont {
        display: inline-flex;
        width: auto;
        top: 160px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .content.sales-pav > .sales-pav-main .family-cont {
        left: 0;
        right: 0;
        margin: 0 auto;

        padding: 0 40px;
    }
}
@media (max-width: 576px) {
    .content.sales-pav > .sales-pav-main {
        height: calc(100vh - 72px);
    }
    .content.sales-pav > .sales-pav-main .family-cont {
        left: 4px;
        bottom: -4px;
        padding: 0;
    }


    .content.sales-pav > .sales-pav-carousel {
        height: calc(100vh - 72px);
        max-height: none;
    }
    .content.sales-pav > .sales-pav-carousel .carousel-item img {
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
    }
    .content.sales-pav > .sales-pav-main .bg-image {
        bottom: -180px;
    }


    .content.sales-pav > .info-section {
        height: calc(100vh - 72px);
        padding: 0 24px;
    }
    .content.sales-pav > .info-section > .info-card > h2 {
        font-size: 28px;
    }
    .content.sales-pav > .info-section > .info-card > .btn-cont a {
        font-size: 16px;
        padding: 10px 20px;
        max-width: 280px;
    }
    .content.sales-pav > .sales-pav-carousel .text-content > div {
        margin-left: 0;
        padding-left: 40px;
        padding-right: 40px;
    }
    .content.sales-pav > .sales-pav-carousel .text-content {
        width: 100%;
        /* background-image: linear-gradient(90deg, rgba(0, 28, 55, 0.9), rgba(0, 28, 55, 0.75), rgba(0, 28, 55, 0.4), rgba(0, 0, 0, 0)); */
        background-image: none;
        background-color: rgba(0, 28, 55, .75);
        min-width: 0;
        padding: 40px 12px;
    }
    .content.sales-pav > .sales-pav-carousel .text-content > div h2 {
        text-align: center;
        font-size: 32px;
    }
    .content.sales-pav > .sales-pav-carousel .text-content > div p {
        text-align: center;
        font-size: 16px;
    }
    .content.sales-pav > .sales-pav-main .anim-cont .anim-item {
        height: 36px;       
    }

    .content.sales-pav > .sales-pav-main .hand-rails {
        bottom: -20px;
    }
    .content.sales-pav > .sales-pav-main .hand-rails img {
        max-width: 1440px;
    }
}
@media (max-width: 425px) {
    .content.sales-pav > .sales-pav-main .hand-rails {
        bottom: -48px;
    }
}
@media (max-width: 375px) {
    .content.sales-pav > .sales-pav-main .hand-rails {
        bottom: -60px;
    }
    .content.sales-pav > .info-section > .info-card > .btn-cont {
        padding: 32px 24px;
    }
    .content.sales-pav > .sales-pav-main .anim-cont .anim-item {
        height: 32px;       
    }
}
@media (max-width: 320px) {
    .content.sales-pav > .sales-pav-main .hand-rails {
        bottom: -72px;
    }
    .content.sales-pav > .info-section {
        padding: 0 12px;
    }
    .content.sales-pav > .sales-pav-main .anim-cont .anim-item {
        height: 28px;       
    }
}